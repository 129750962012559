import axios from "axios";
import { TyreInterface } from "../interfaces/TyreInterface";
import authHeader from "./auth-header";
let API_URL = "";
if(process.env.NODE_ENV === 'development') {
  API_URL = "http://api.service-bridge.test/api/";
} else {
  API_URL = "https://service-bridge.eastus.cloudapp.azure.com:8443/api/";
}
// Accounts API
export const getAllAccounts = (page?:null|string|number) => {
  let url = API_URL + "admin/accounts";
  if(page){
    url = url + "?page=" + page;
  }
  return axios
    .get(url, { headers: authHeader() })
    .then((response) => response.data);
};
export const getAccount = (id: number | string) => {
  return axios
    .get(API_URL + "admin/accounts/" + id, { headers: authHeader() })
    .then((response) => response.data);
};
export const getAccountNonAdmin = () => {
  return axios
    .get(API_URL + "account", { headers: authHeader() })
    .then((response) => response.data);
};
export const createAccount = (
  name: string,
  description: string|null,
  account_number: string,
  contract_name: string,
  contract_account_number: string,
  webfleet_username: string,
  webfleet_password: string,
  webfleet_api_key: string
) => {
  return axios
    .post(
      API_URL + "admin/accounts",
      {
        name,
        description,
        account_number,
        contract_name,
        contract_account_number,
        webfleet_username,
        webfleet_password,
        webfleet_api_key,
      },
      { headers: authHeader() }
    )
    .then((response) => response.data);
};
export const updateAccount = (
  id: string | number,
  name: string,
  description: string|null,
  account_number: string,
  contract_name: string,
  contract_account_number: string,
  webfleet_username: string,
  webfleet_password: string,
  webfleet_api_key: string
) => {
  return axios
    .put(
      API_URL + "admin/accounts/" + id,
      {
        name,
        description,
        account_number,
        contract_name,
        contract_account_number,
        webfleet_username,
        webfleet_password,
        webfleet_api_key,
      },
      { headers: authHeader() }
    )
    .then((response) => response.data);
};
export const updateAccountNonAdmin = (
  name: string,
  description: string|null,
  account_number: string,
  contract_name: string,
  contract_account_number: string,
  webfleet_username: string,
  webfleet_password: string,
  webfleet_api_key: string
) => {
  return axios
    .put(
      API_URL + "account/update",
      {
        name,
        description,
        account_number,
        contract_name,
        contract_account_number,
        webfleet_username,
        webfleet_password,
        webfleet_api_key,
      },
      { headers: authHeader() }
    )
    .then((response) => response.data);
};
export const deleteAccount = (id: number) => {
  return axios
    .delete(API_URL + "admin/accounts/" + id, { headers: authHeader() })
    .then((response) => response.data);
};

// Vehicles API
export const getAllVehicles = (page?:null|string|number) => {
  let url = API_URL + "vehicles";
  if(page){
    url = url + "?page=" + page;
  }
  return axios
    .get(url, { headers: authHeader() })
    .then((response) => response.data);
};
export const getVehicle = (id: number | string) => {
  return axios
    .get(API_URL + "vehicles/" + id, { headers: authHeader() })
    .then((response) => response.data);
};

export const getVehicleByAccessToken = (
  id: number | string,
  access_token: number | string
) => {
  return axios
    .get(API_URL + "vehicle/" + id + "?access_token=" + access_token, {
      headers: authHeader(),
    })
    .then((response) => response.data);
};
export const extractVehicleData = (
  license_plate: string,
  license_plate_webfleet: string,
  country: string,
  account_id: string | number
) => {
  return axios
    .post(
      API_URL + "vehicle-data",
      {
        license_plate,
        license_plate_webfleet,
        account_id,
        country,
      },
      { headers: authHeader() }
    )
    .then((response) => response.data);
};

export const createVehicle = (
  license_plate: string,
  fleet_reference: string,
  fleetbridge_vehicle_id: string,
  label: string,
  latitude: string,
  latitude_mdeg: string,
  layout_code: string,
  location_id: string,
  location_name: string,
  longitude: string,
  longitude_mdeg: string,
  object_uid_webfleet: string,
  odometer_reading: string,
  position_text: string,
  type: string,
  tyres: TyreInterface[],
  vin: string,
  country: string,
  notes: string,
  driver_name: string,
  driver_phone: string,
  tyres_db: [],
  account_id: string | number
) => {
  return axios
    .post(
      API_URL + "vehicles",
      {
        license_plate,
        fleet_reference,
        fleetbridge_vehicle_id,
        label,
        latitude,
        latitude_mdeg,
        layout_code,
        location_id,
        location_name,
        longitude,
        longitude_mdeg,
        object_uid_webfleet,
        odometer_reading,
        position_text,
        type,
        tyres,
        vin,
        country,
        notes,
        driver_name,
        driver_phone,
        tyres_db,
        account_id,
      },
      { headers: authHeader() }
    )
    .then((response) => response.data);
};

export const updateVehicle = (
  id: number | string,
  fleet_reference: string,
  fleetbridge_vehicle_id: string,
  label: string,
  latitude: string,
  latitude_mdeg: string,
  layout_code: string,
  license_plate: string,
  location_id: string,
  location_name: string,
  longitude: string,
  longitude_mdeg: string,
  object_uid_webfleet: string,
  odometer_reading: string,
  position_text: string,
  type: string,
  tyres: TyreInterface[],
  vin: string,
  country: string,
  notes: string,
  driver_name: string,
  driver_phone: string,
  tyres_db: [],
  account_id: string | number
) => {
  return axios
    .put(
      API_URL + "vehicles/"+id,
      {
        fleet_reference,
        fleetbridge_vehicle_id,
        label,
        latitude,
        latitude_mdeg,
        layout_code,
        license_plate,
        location_id,
        location_name,
        longitude,
        longitude_mdeg,
        object_uid_webfleet,
        odometer_reading,
        position_text,
        type,
        tyres,
        vin,
        country,
        notes,
        driver_name,
        driver_phone,
        tyres_db,
        account_id,
      },
      { headers: authHeader() }
    )
    .then((response) => response.data);
};
export const deleteVehicle = (id: number | string) => {
  return axios
    .delete(API_URL + "vehicles/" + id, { headers: authHeader() })
    .then((response) => response.data);
}

// Services API
export const getAllServices = (page?:string|number|null) => {
  let url = API_URL + "services";
  if(page){
    url = url + "?page="+page;
  }

  return axios
    .get(url, { headers: authHeader() })
    .then((response) => response.data);
};

export const getService = (id: number | string) => {
  return axios
    .get(API_URL + "services/" + id, { headers: authHeader() })
    .then((response) => response.data);
};
export const createRequest = (
  access_token: string | null = null,
  vehicle_id: string | null,
  tyre_position: string | null = null,
  trailer: boolean,
  tyre_steer_or_drive: string,
  tyre_rim_diameter: string,
  tyre_serie: string,
  tyre_width: string,
  tyre_ean: string | null = null,
  tyre_ipc: string | null = null,
  tyre_brand: string,
  tyre_load: string,
  tyre_speed_rating: string,
  tyre_type: string,
  location_lat: string,
  location_lng: string,
  location_type: string,
  driver_name: string,
  driver_phone: string,
  driver_language: string,
  note: string | null = null,
  tyre_description: string,
  service_type: string,
  account_id: string | number,
) => {
  return axios
    .post(
      API_URL + "services",
      {
        access_token,
        vehicle_id,
        tyre_position,
        trailer,
        tyre_steer_or_drive,
        tyre_rim_diameter,
        tyre_serie,
        tyre_width,
        tyre_ean,
        tyre_ipc,
        tyre_brand,
        tyre_load,
        tyre_speed_rating,
        tyre_type,
        location_lat,
        location_lng,
        location_type,
        driver_name,
        driver_phone,
        driver_language,
        note,
        tyre_description,
        service_type,
        account_id,
      },
      { headers: authHeader() }
    )
    .then((response) => response.data);
};
// Service Requests API
export const getAllServiceRequests = () => {
  return axios
    .get(API_URL + "services", { headers: authHeader() })
    .then((response) => response.data);
};
export const getServiceRequest = (id: number | string) => {
  return axios
    .get(API_URL + "services/" + id, { headers: authHeader() })
    .then((response) => response.data);
};

export const getServiceRequestsPerVehicle = (id: number | string, access_token:string, date?:null | string, page?:any) => {
  let url = API_URL + "services-per-vehicle/" + id + "?access_token=" + access_token;
  if(date){
    url = url + "&date="+date;
  }
  if(page){
    url = url + "&page="+page;
  }
  return axios
    .get(url, { headers: authHeader() })
    .then((response) => response.data);
};

export const getServiceRequestsPerAccount = (id: number | string, access_token:string, page?:null|number|string) => {
  let url = API_URL + "services-per-account/" + id + "?access_token=" + access_token;
  if(page){
    url = url + "&page="+page;
  }
  return axios
    .get(url, { headers: authHeader() })
    .then((response) => response.data);
};

export const getServiceRequestPerAccessToken = (id: number | string, access_token:string) => {
  let url = API_URL + "service-by-access-token/" + id + "?access_token=" + access_token;
  return axios
    .get(url, { headers: authHeader() })
    .then((response) => response.data);
};


export const createServiceRequest = (serviceRequest: any) => {
  return axios
    .post(API_URL + "services", serviceRequest, { headers: authHeader() })
    .then((response) => response.data);
};
export const updateServiceRequest = (
  id: number | string,
  serviceRequest: any
) => {
  return axios
    .put(API_URL + "services/" + id, serviceRequest, { headers: authHeader() })
    .then((response) => response.data);
};
export const deleteServiceRequest = (id: number | string) => {
  return axios
    .delete(API_URL + "services/" + id, { headers: authHeader() })
    .then((response) => response.data);
};

export const cancelServiceRequest = (id: number | string) => {
  return axios
    .get(API_URL + "services-cancel/" + id, { headers: authHeader() })
    .then((response) => response.data);
};
export const cancelServiceRequestByAccessToken = (id: number | string, access_token:string) => {
  return axios
    .get(API_URL + "services-cancel-by-access-token/" + id + "?access_token="+access_token, { headers: authHeader() })
    .then((response) => response.data);
};

// Maintenance requests API
export const createMaintenanceRequest = (
  access_token: string | null = null,
  vehicle_id: string | null,
  note: string | null = null,
  service_type: string,
  trailer: boolean,
  maintenance_services: {},
  owner_email: string, // email of the owner of the vehicle
  requested_date: string, // date of the request in format YYYY-MM-DD HH:MM:SS
  dealer_id: string | number,
  account_id: string | number,
) => {
  return axios
    .post(
      API_URL + "maintenances",
      {
        access_token,
        vehicle_id,
        note,
        service_type,
        trailer,
        maintenance_services, // array of stringsm
        owner_email, // email of the owner of the vehicle
        requested_date, // date of the request in format YYYY-MM-DD HH:MM:SS
        dealer_id, // id of the dealer that is requesting the service
        account_id,
      },
      { headers: authHeader() }
    )
    .then((response) => response.data);
};

// Get all dealers
export const getAllDealers = (type?:null|string) => {
  let url = `${API_URL}dealers-public${type ? "?type="+type : ""}`;
  return axios
    .get(url, { headers: authHeader() })
    .then((response) => response.data);
};
export const getAllDealersByDistance = (type:string,lat:string|number,lng:string|number,country:string) => {
  let url = `${API_URL}dealers-public-by-distance?type=${type}&lat=${lat}&lng=${lng}&country=${country}`;
  return axios
    .get(url, { headers: authHeader() })
    .then((response) => response.data);
};


// Get all dealers
export const getAllMaintenanceTypes = (type?:null|string) => {
  let url = `${API_URL}maintenance-types${type ? "?type="+type : ""}`;
  return axios
    .get(url, { headers: authHeader() })
    .then((response) => response.data);
};

// Users API
export const addUser = (
  email: string,
  account_id: string | number,
  role: number
) => {
  return axios
    .post(
      API_URL + "auth/invite",
      {
        email,
        account_id,
        role,
      },
      { headers: authHeader() }
    )
    .then((response) => response.data);
};
export const deleteUser = (id: number | string) => {
  return axios
    .delete(API_URL + "user/" + id, { headers: authHeader() })
    .then((response) => response.data);
}

// Public content
export const getAllCountries = () => {
  return axios.get(API_URL + "countries").then((response) => {
    localStorage.setItem("countries", JSON.stringify(response.data.data));
  });
};
export const getAllLanguages = () => {
  return axios.get(API_URL + "languages").then((response) => {
    localStorage.setItem("languages", JSON.stringify(response.data.data));
  });
};
export const getAllVehicleTypes = () => {
  return axios.get(API_URL + "vehicle-types").then((response) => {
    localStorage.setItem("vehicle_types", JSON.stringify(response.data.data));
  });
};
