import {useEffect} from 'react';
import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../components/Layout';
import { useParams } from "react-router-dom";

const AdminDashoard =  () => {
 
  return(
      <React.Fragment>
        <h2>Super admin Dashboard</h2>
      </React.Fragment>
  );
}

export default AdminDashoard;