import * as React from 'react';
import styles from './Logo.module.scss';
import LogoImage from '../../assets/images/logo.svg';
import { Link } from 'react-router-dom';
const Logo:React.FC = () => {
    return (
        <div className={styles.logoWrapper}>
            <Link to={"/"}><h2 className={styles.logo}>Service<span className={styles.logoRed}>Bridge</span></h2></Link>
            {/* <h2 className={styles.logo}>Service<span className={styles.logoRed}>Bridge</span> <span className={styles.logoBy}>by</span></h2>
            <img className={styles.logoImage} src={LogoImage} alt="Service Bridge Logo" />   */}
        </div>
    );
}

export default Logo;