import React, { Fragment, useEffect, useRef, useState } from "react";
import Layout from "../../../components/Layout/Layout";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../../utils/helpers";
import { useLoadScript } from "@react-google-maps/api";

import {
  createMaintenanceRequest,
  getAllDealersByDistance,
  getVehicleByAccessToken,
} from "../../../services/user.service";
import { getCurrentUser } from "../../../services/auth.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";

import Map from "../../../components/Map/Map";
import DayTimePickerWrapper from "../../../components/DayTimePicker/DayTimePicker";

import VehicleDetails from "../../../components/VehicleDetails/VehicleDetails";
import Tyres from "../../../components/Tyres/Tyres";
import ServiceExtraInformation from "../../../components/ServiceExtraInformation/ServiceExtraInformation";
import DealersList from "../../../components/DealersList/DealersList";
import TruckOrTrailer from "../../../components/TruckOrTrailer/TruckOrTrailer";
import MaintenanceServices from "../../../components/MaintenanceServices/MaintenanceServices";
import { TyreInterface } from "../../../interfaces/TyreInterface";
import Select from "react-select";
import { EUROPEAN_CONTRIES } from "../../../utils/european_contries";

const Maintenance: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const navigate = useNavigate();
  const [vehicleTrailer, setVehicleTrailer] = useState<boolean>(false);
  const [owner_email, setOwnerEmail] = useState<string>(""); // email of the owner of the vehicle
  const [note, setNote] = useState<string>("");
  const [dealers, setDealers] = useState<any[]>([]);
  const [maintenanceTypes, setMaintenanceTypes] = useState<any[]>([]);
  const [selectedDealer, setSelectedDealer] = useState<any>(null);
  const logged_in = getCurrentUser() !== null;
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
    libraries: ["places"],
  });
  const [markers, setMarkers] = useState<any[]>([]);
  const [dealerView, setDealerView] = useState<string>("list");
  const [selectedAddress, setSelectedAddress] = useState<string>("");
  const [selectedAddressLatLng, setSelectedAddressLatLng] = useState<any>({});
  const [chosenCurrentVehicleLocation, setChosenCurrentVehicleLocation] =
    useState<boolean>(false);
  const [tyreWidth, setTyreWidth] = useState<string>("");
  const [tyreSerie, setTyreSerie] = useState<string>("");
  const [tyreRimDiameter, setTyreRimDiameter] = useState<string>("");
  const [tyreBrand, setTyreBrand] = useState<string>("");
  const [tyreLoad, setTyreLoad] = useState<string>("");
  const [tyreSpeedRating, setTyreSpeedRating] = useState<string>("");
  const [tyreType, setTyreType] = useState<string>("");
  const TRAILER_TYPES = [
    "trailer",
    "truck_trailer",
    "heavyweight_truck_trailer",
  ];
  const TRUCK_TYPES = [
    "garbage_truck",
    "heavy_truck",
    "heavytruck_us",
    "heavyweight_truck",
    "medium_truck",
    "pallet_truck",
    "tanker_truck",
    "truck",
    "truck_loader_arm",
    "truck_with_trailer",
    "truck_wrecker",
  ];
  const HEAVY_VEHICLES = [
    "bus",
    "boom_lift_truck",
    "boom_lift",
    "concrete_lorry",
    "dump_truck",
    "dump_truck_open_top",
    "excavator"
  ]
  const [typeOfVehicle, setTypeOfVehicle] = useState<string>("");
  let query = useQuery();
  const [selectedServices, setSelectedServices] = useState<any[]>([]);
  const access_token = query.get("access_token");
  const vehicle_id = query.get("vehicle_id");
  const [date, setDate] = useState<any>(null);
  const [showScheduler, setShowScheduler] = useState<boolean>(false);
  const [vehicle, setVehicle] = useState<any>();
  const [selectedAxle, setSelectedAxle] = useState<string[]>([]);
  const [selectedAxleRetreaded, setSelectedAxleRetreaded] = useState<string[]>([]);

  const [selectedBrakePads, setSelectedBrakePads] = useState<string>("");
  const [selectedBrakeDisks, setSelectedBrakeDisks] = useState<string>("");
  const [selectedWheelAlignment, setSelectedWheelAlignment] =
    useState<string>("");
  const [selectedWiperBlades, setSelectedWiperBlades] = useState<string>("");
  const [selectedShockAbsorbers, setSelectedShockAbsorbers] =
    useState<string>("");

  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" &&
      !logged_in &&
      !document.referrer.includes("webfleet.com")
    ) {
      return navigate("/");
    }
    if (!access_token || !vehicle_id) {
      return navigate("/");
    } else {
      getVehicleByAccessToken(vehicle_id, access_token)
        .then((res) => {
          setVehicle(res.data);
          setVehicleTrailer(TRAILER_TYPES.includes(res.data.type.slug));
          if (
            TRUCK_TYPES.includes(res.data.type.slug) ||
            TRAILER_TYPES.includes(res.data.type.slug) ||
            HEAVY_VEHICLES.includes(res.data.type.slug)
          ) {
            setTypeOfVehicle("commercial");
          } else {
            setTypeOfVehicle("consumer");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [access_token, navigate, vehicle_id, typeOfVehicle]);

  const handleSelectedServices = (service_slug: string) => {
    if (selectedServices.includes(service_slug)) {
      setSelectedServices(
        selectedServices.filter((service) => service !== service_slug)
      );
    } else {
      setSelectedServices([...selectedServices, service_slug]);
    }
  };

  const renderDealers = () => {
    if (dealers.length > 0) {
      if (dealerView === "list") {
        return (
          <DealersList
            dealers={dealers}
            selectedDealer={selectedDealer}
            setSelectedDealer={setSelectedDealer}
            setMaintenanceTypes={setMaintenanceTypes}
            dealers_per_page={5}
            starting_point={
              Object.keys(selectedAddressLatLng).length > 0
                ? selectedAddressLatLng
                : {
                    lat: vehicle.latitude_mdeg / 1000000,
                    lng: vehicle.longitude_mdeg / 1000000,
                  }
            }
            title="Dealers"
          />
        );
      } else {
        return renderMap();
      }
    }
  };
  const renderMap = () => {
    // let lat:number = vehicle.latitude_mdeg/ 1000000;
    // let lng:number = vehicle.longitude_mdeg/ 1000000;
    if (
      isLoaded &&
      !loadError &&
      (selectedAddress !== "" || chosenCurrentVehicleLocation) &&
      dealers.length > 0
    ) {
      let lat: number;
      let lng: number;
      if (chosenCurrentVehicleLocation) {
        lat = vehicle.latitude_mdeg / 1000000;
        lng = vehicle.longitude_mdeg / 1000000;
      } else {
        lat = selectedAddressLatLng.lat;
        lng = selectedAddressLatLng.lng;
      }
      let center = { lat: lat, lng: lng };
      if (
        selectedDealer &&
        selectedDealer.latitude &&
        selectedDealer.longitude
      ) {
        center = {
          lat: parseFloat(selectedDealer.latitude.replace(",", ".")),
          lng: parseFloat(selectedDealer.longitude.replace(",", ".")),
        };
      } else if (markers.length > 0) {
        center = markers[0].position;
      }

      return (
        <div className="row mt-20">
          <div className="col-md-12">
            <Map
              zoom={11}
              handleSelectedDealerCallback={handleSelectedDealer}
              center={center}
              markers={markers}
              currentPosition={{
                id: 0,
                name: "Chosen location",
                position: { lat: lat, lng: lng },
              }}
            />
          </div>
          <div className="col-md-12 mt-20">
            {selectedDealer ? renderSelectedDealerMap() : null}
          </div>
        </div>
      );
    }
  };
  const handleSelectedDealer = (id: number) => {
    if (id === 0) {
      return;
    } else {
      let dealer = dealers.find((item: any) => item.id === id);
      setSelectedDealer(dealer);
      setMaintenanceTypes(dealer.maintenance_types);
    }
  };
  const renderSelectedDealerMap = () => {
    return (
      <div className="row background-grey">
        <div className="col-12 mt-20">
          <h3 className="m-0">Selected Dealer</h3>
        </div>
        <div className="col-md-6 mt-20">
          <h4 className="m-0">
            {selectedDealer.name} - {selectedDealer.brand}
          </h4>
          <p>
            {selectedDealer.address ? `${selectedDealer.address},` : null}{" "}
            {selectedDealer.city}
          </p>
          <p>
            {selectedDealer.zip ? `${selectedDealer.zip},` : null}{" "}
            {selectedDealer.country}
          </p>
        </div>
        <div className="col-md-6">
          {/* <div className="d-flex align-items-center"> */}

          {/* <p><a className="btn btn-primary" href={`mailto:${selectedDealer.phone}`}>Phone</a></p> */}
          <p>
            <a
              className="btn btn-primary width-100"
              target="_blank"
              href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&origin=${
                Object.keys(selectedAddressLatLng).length > 0
                  ? `${selectedAddressLatLng.lat},${selectedAddressLatLng.lng}`
                  : `${vehicle.latitude_mdeg / 1000000},${
                      vehicle.longitude_mdeg / 1000000
                    }`
              }&destination=${selectedDealer.latitude.replace(
                ",",
                "."
              )},${selectedDealer.longitude.replace(",", ".")}`}
            >
              Show route
            </a>
          </p>
          <p>
            <a
              className="btn btn-outline-primary width-100"
              href={`mailto:${selectedDealer.email}`}
            >
              Email
            </a>
          </p>
          {/* <p><a className="btn btn-primary" href={selectedDealer.email} target="_blank"></a></p> */}
          {/* </div> */}
        </div>
      </div>
    );
  };

  const handleSelectedAddress = (e: any) => {
    setSelectedAddress(e.label);
    setSelectedDealer(null); // reset selected dealer when address is changed
    let country = e.value.terms[e.value.terms.length - 1].value;
    geocodeByAddress(e.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setSelectedAddressLatLng({ lat: lat, lng: lng });
        setChosenCurrentVehicleLocation(false);
        console.log("Successfully got latitude and longitude", { lat, lng });
        getAllDealersByDistance(typeOfVehicle, lat, lng, country).then(
          (res) => {
            setDealers(res.data);
            let dealsers = res.data.map((item: any) => {
              return {
                id: item.id,
                name: `${item.name} - ${item.brand}`,
                position: {
                  lat: parseFloat(item.latitude.replace(",", ".")),
                  lng: parseFloat(item.longitude.replace(",", ".")),
                },
              };
            });
            setMarkers(dealsers);
          }
        );
      });
  };
  const handleCurrentLocation = () => {
    setChosenCurrentVehicleLocation(true);
    let lat: number = vehicle.latitude_mdeg / 1000000;
    let lng: number = vehicle.longitude_mdeg / 1000000;
    let country = vehicle.country.label;
    getAllDealersByDistance(typeOfVehicle, lat, lng, country).then((res) => {
      setDealers(res.data);
      console.log(res.data);
      let dealsers = res.data.map((item: any) => {
        return {
          id: item.id,
          name: `${item.name} - ${item.brand}`,
          position: {
            lat: parseFloat(item.latitude.replace(",", ".")),
            lng: parseFloat(item.longitude.replace(",", ".")),
          },
        };
      });
      setMarkers(dealsers);
    });
    setSelectedAddress(`Current vehicle location - ${lat}, ${lng}`);
  };

  const renderGooglePlacesAutocomplete = () => {
    if (isLoaded && !loadError) {
      return (
        <div className="mt-20">
          <GooglePlacesAutocomplete
            // minLengthAutocomplete={3}
            // autocompletionRequest={{
            //   componentRestrictions: {
            //     // europe
            //     country: EUROPEAN_CONTRIES,
            //   },
            // }}
            selectProps={{
              placeholder: "Enter your address",
              onChange: (e: any) => {
                handleSelectedAddress(e);
              },
              value: selectedAddress
                ? { label: selectedAddress, value: selectedAddress }
                : "",
              
            }}
          />
          <div className="d-flex justify-content-between">
            <span
              onClick={() => {
                handleCurrentLocation();
              }}
              className="current-vehicle-location"
            >
              Use current vehicle location
            </span>
            {dealerView === "list" ? (
              <span
                onClick={() => setDealerView("map")}
                className="current-vehicle-location"
              >
                Show map
              </span>
            ) : (
              <span
                onClick={() => setDealerView("list")}
                className="current-vehicle-location"
              >
                Show list
              </span>
            )}
          </div>
        </div>
      );
    }
  };
  const timeSlotValidator = (slotTime: Date) => {
    let currentTime = new Date();
    currentTime.setUTCHours(0, 0, 0, 0);
    let days_to_add = 14;
    let available_date = new Date(
      currentTime.setDate(currentTime.getDate() + days_to_add)
    );
    const openTime = new Date(
      slotTime.getFullYear(),
      slotTime.getMonth(),
      slotTime.getDate(),
      8,
      0,
      0
    );
    const closeTime = new Date(
      slotTime.getFullYear(),
      slotTime.getMonth(),
      slotTime.getDate(),
      17,
      0,
      0
    );
    const isValid =
      slotTime.getDay() !== 6 &&
      slotTime.getDay() !== 7 &&
      slotTime > available_date &&
      slotTime.getTime() >= openTime.getTime() &&
      slotTime.getTime() <= closeTime.getTime();
    return isValid;
  };

  const schedulerTheme = {
    primary: "#fff",
    secondary: "#EE2E24",
    background: "#EE2E24", // This should match the container background
    buttons: {
      disabled: {
        color: "#333",
        background: "#f0f0f0",
      },
      confirm: {
        color: "#EE2E24",
        background: "#fff",
        hover: {
          color: "",
          background: "#fff",
        },
      },
    },
    feedback: {
      success: {
        color: "#29aba4",
      },
      failed: {
        color: "#eb7260",
      },
    },
  };

  const handleScheduled = (dateTime: Date) => {
    console.log("scheduled: ", dateTime);
    setDate(dateTime);
  };
  const handleClickOnAxle = (tyre: any) => {
    let tyre_position = [parseInt(tyre.position.charAt(0)), parseInt(tyre.position.charAt(2))];
    let selectedTyres = [tyre_position[0] + "L" + tyre_position[1], tyre_position[0] + "R" + tyre_position[1]];
    if (selectedAxle.includes(selectedTyres[0]) && selectedAxle.includes(selectedTyres[1])) {
      setSelectedAxle(
        selectedAxle.filter(
          (item: any) => item !== selectedTyres[0] && item !== selectedTyres[1]
        )
      );
    } else {
      setSelectedAxle([...selectedAxle, ...selectedTyres]);
    }
    console.log(selectedAxle);
  };

  const handleClickOnAxleRetreaded = (tyre: any) => {
    let tyre_position = [parseInt(tyre.position.charAt(0)), parseInt(tyre.position.charAt(2))];
    let selectedTyres = [tyre_position[0] + "L" + tyre_position[1], tyre_position[0] + "R" + tyre_position[1]];
    if (selectedAxleRetreaded.includes(selectedTyres[0]) && selectedAxleRetreaded.includes(selectedTyres[1])) {
      setSelectedAxleRetreaded(
        selectedAxleRetreaded.filter(
          (item: any) => item !== selectedTyres[0] && item !== selectedTyres[1]
        )
      );
    } else {
      setSelectedAxleRetreaded([...selectedAxleRetreaded, ...selectedTyres]);
    }
  };
  const renderTyreDimensionsFields = () => {
    return (
      <div className="row">
        <div className="col-md-4 col-12">
          <div className="form-group">
            <label htmlFor="tyre_width">Width</label>
            <input
              name="tyre_width"
              type="text"
              className="form-control"
              onChange={(e: any) => {
                setTyreWidth(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="form-group">
            <label htmlFor="tyre_serie">Ratio</label>
            <input
              name="tyre_serie"
              type="text"
              className="form-control"
              onChange={(e: any) => {
                setTyreSerie(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="form-group">
            <label htmlFor="tyre_rim_diameter">Rim Diameter</label>
            <input
              name="tyre_rim_diameter"
              type="text"
              className="form-control"
              onChange={(e: any) => {
                setTyreRimDiameter(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="form-group">
            <label htmlFor="tyre_brand">Brand</label>
            <input
              name="tyre_brand"
              type="text"
              className="form-control"
              onChange={(e: any) => {
                setTyreBrand(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="form-group">
            <label htmlFor="tyre_load">Load</label>
            <input
              name="tyre_load"
              type="text"
              className="form-control"
              onChange={(e: any) => {
                setTyreLoad(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="form-group">
            <label htmlFor="tyre_speed_rating">Speed Rating</label>
            <input
              name="tyre_speed_rating"
              type="text"
              className="form-control"
              onChange={(e: any) => {
                setTyreSpeedRating(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="form-group">
            <label htmlFor="tyre_type">Tyre type</label>
            <Select
              name="tyre_type"
              className="select-dropdown"
              options={[
                { value: "Summer", label: "Summer" },
                { value: "Winter", label: "Winter" },
                { value: "AllSeasons", label: "All Seasons" },
                { value: "Nordic", label: "Nordic" },
              ]}
              onChange={(value: any) => {
                setTyreType(value.value);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const handleTyreInfo = (key:string, info:string, tyre_id:number) => {
     let selected_tyre = vehicle.tyres.find((tyre:any) => tyre.id === tyre_id);
      if(selected_tyre){
        selected_tyre[key] = info;
        setVehicle({...vehicle, tyres: [...vehicle.tyres]});
      }
  };


  const renderTyreFields = (selectedAxle:any) => {
    const selectedTyres = selectedAxle.map((item: any) => {
      return vehicle.tyres.find((tyre: any) => tyre.position === item);
    });
    
    return selectedTyres.map((tyre: any, index: number) => {
      if(!tyre.description){
        return (
            <div className="col-12" key={index}>
              <h3>Tyre {tyre.position} - {tyre.tyre_brand}</h3>
              <div className="row">
                <div className="col-md-4 col-12">
                  <div className="form-group">
                    <label htmlFor="tyre_width">Width</label>
                    <input
                      name="tyre_width"
                      type="text"
                      className="form-control"
                      value={tyre.width}
                      onChange={(e: any) => {
                        handleTyreInfo("width", e.target.value, tyre.id);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="form-group">
                    <label htmlFor="tyre_serie">Ratio</label>
                    <input
                      name="serie"
                      type="text"
                      className="form-control"
                      value={tyre.serie}
                      onChange={(e: any) => {
                        handleTyreInfo("serie", e.target.value, tyre.id);

                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="form-group">
                    <label htmlFor="tyre_rim_diameter">Rim Diameter</label>
                    <input
                      name="tyre_rim_diameter"
                      type="text"
                      className="form-control"
                      value={tyre.rim_diameter}
                      onChange={(e: any) => {
                        handleTyreInfo("rim_diameter", e.target.value, tyre.id);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="form-group">
                    <label htmlFor="tyre_brand">Brand</label>
                    <input
                      name="tyre_brand"
                      type="text"
                      className="form-control"
                      value={tyre.tyre_brand}
                      onChange={(e: any) => {
                        handleTyreInfo("tyre_brand", e.target.value, tyre.id);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="form-group">
                    <label htmlFor="tyre_load">Load</label>
                    <input
                      name="tyre_load"
                      type="text"
                      className="form-control"
                      onChange={(e: any) => {
                        handleTyreInfo("load_index_1", e.target.value, tyre.id);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="form-group">
                    <label htmlFor="tyre_speed_rating">Speed Rating</label>
                    <input
                      name="tyre_speed_rating"
                      type="text"
                      className="form-control"
                      value={tyre.speed_index_1}
                      onChange={(e: any) => {
                        handleTyreInfo("speed_index_1", e.target.value, tyre.id);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="form-group">
                    <label htmlFor="tyre_type">Tyre type</label>
                    <Select
                      name="tyre_type"
                      className="select-dropdown"
                      options={[
                        { value: "Summer", label: "Summer" },
                        { value: "Winter", label: "Winter" },
                        { value: "AllSeasons", label: "All Seasons" },
                        { value: "Nordic", label: "Nordic" },
                      ]}
                      value={{value: tyre.season, label: tyre.season}}
                      onChange={(value: any) => {
                        handleTyreInfo("season", value.value, tyre.id);
                      }}
                    />
                  </div>
                </div>

              </div>
            </div>
        );     }
      else{return (
          <div className="col-md-4 col-12" key={index}>
            <div className="form-group">
              <h3>Tyre {tyre.position} - {tyre.tyre_brand}</h3>
                <p>{tyre.description}</p>
              </div>
            </div>
      )}
    });
     
  }

  const renderExtraServices = () => {
    let elements_to_render = [];
    // vehicle.tyres = [];
    if (selectedServices.includes("tyres") && vehicle.tyres.length > 0) {
      console.log(selectedAxle);
      elements_to_render.push(
        <Tyres
          title="Select tyre sets"
          onClickCallback={handleClickOnAxle}
          selected_axle={selectedAxle}
          vehicle_tyres={vehicle.tyres}
        />,
        <div className="row">
          <h3 className="mt-20">Selected Tyres</h3>
          {renderTyreFields(selectedAxle)}
        </div>
      );
    }
    if (selectedServices.includes("tyres") && vehicle.tyres.length === 0) {
      elements_to_render.push(
        <h3 className="pt-20">
           Please fill in the tire dimensions for easier case handling by your
           Roadside Assistance provider
         </h3>,
         renderTyreDimensionsFields(),
      );
    }
    if (selectedServices.includes("brake-pads")) {
      elements_to_render.push(
        <ServiceExtraInformation
          title="Brake pads"
          onClickCallback={(brake_pad) => setSelectedBrakePads(brake_pad.value)}
          selected_option={selectedBrakePads}
          options={[
            { name: "All", value: "all" },
            { name: "Rear", value: "rear" },
            { name: "Front", value: "front" },
          ]}
        />
      );
    }
    if (selectedServices.includes("brake-discs")) {
      elements_to_render.push(
        <ServiceExtraInformation
          title="Brake disks"
          onClickCallback={(brake_disk) =>
            setSelectedBrakeDisks(brake_disk.value)
          }
          selected_option={selectedBrakeDisks}
          options={[
            { name: "All", value: "all" },
            { name: "Rear", value: "rear" },
            { name: "Front", value: "front" },
          ]}
        />
      );
    }
    if (selectedServices.includes("wheel-alignment")) {
      elements_to_render.push(
        <ServiceExtraInformation
          title="Wheel Alignment"
          onClickCallback={(wheel_alignment) =>
            setSelectedWheelAlignment(wheel_alignment.value)
          }
          selected_option={selectedWheelAlignment}
          options={[
            { name: "All", value: "all" },
            { name: "Rear", value: "rear" },
            { name: "Front", value: "front" },
          ]}
        />
      );
    }
    if (selectedServices.includes("wiper-blades")) {
      elements_to_render.push(
        <ServiceExtraInformation
          title="Wiper Blades"
          onClickCallback={(wiper_blades) =>
            setSelectedWiperBlades(wiper_blades.value)
          }
          selected_option={selectedWiperBlades}
          options={[
            { name: "All", value: "all" },
            { name: "Rear", value: "rear" },
            { name: "Front", value: "front" },
          ]}
        />
      );
    }
    if (selectedServices.includes("shock-absorbers")) {
      elements_to_render.push(
        <ServiceExtraInformation
          title="Shock Absobers"
          onClickCallback={(shock_absobers) =>
            setSelectedShockAbsorbers(shock_absobers.value)
          }
          selected_option={selectedShockAbsorbers}
          options={[
            { name: "All", value: "all" },
            { name: "Rear", value: "rear" },
            { name: "Front", value: "front" },
          ]}
        />
      );
    }
    return elements_to_render;
  };
  const renderExtraServicesHeavyVehicles = () => {
    let elements_to_render = [];
    if (selectedServices.includes("tyres") && vehicle.tyres.length > 0) {
      elements_to_render.push(
        <Tyres
          title="Select tyre sets"
          onClickCallback={handleClickOnAxle}
          selected_axle={selectedAxle}
          vehicle_tyres={vehicle.tyres}
        />,
        <div className="row">
          <div className="col-12">
            <h3 className="pt-20">
              Selected tyres
            </h3>
            <div className="row mt-40">
              {renderTyreFields(selectedAxle)}
            </div>
          </div>
        </div>
      );
    }
    if (selectedServices.includes("tyres") && vehicle.tyres.length === 0) {
      elements_to_render.push(
        <h3 className="pt-20">
           Please fill in the tire dimensions for easier case handling by your
           Roadside Assistance provider
         </h3>,
         renderTyreDimensionsFields(),
      );
    }
    if (selectedServices.includes("retreaded-tyres")) {
      elements_to_render.push(
        <Tyres
          title="Select tyre sets - Retreaded tyres"
          onClickCallback={handleClickOnAxleRetreaded}
          selected_axle={selectedAxleRetreaded}
          vehicle_tyres={vehicle.tyres}
        />,
        <div className="row">
          <div className="col-12">
            <h3 className="pt-20">
              Selected tyres
            </h3>
            <div className="row mt-40">
              {renderTyreFields(selectedAxleRetreaded)}
            </div>
          </div>
        </div>
      );
    }
    if (selectedServices.includes("retreaded-tyres") && vehicle.tyres.length === 0) {
      elements_to_render.push(
        <Tyres
          title="Select tyre sets - Retreaded tyres"
          onClickCallback={handleClickOnAxleRetreaded}
          selected_axle={selectedAxleRetreaded}
          vehicle_tyres={vehicle.tyres}
        />
      );
    }
    if (selectedServices.includes("wheel-alignment") && TRUCK_TYPES.includes(vehicle.type.slug)) {
      elements_to_render.push(
        <ServiceExtraInformation
          title="Wheel Alignment"
          onClickCallback={(wheel_alignment) =>
            setSelectedWheelAlignment(wheel_alignment.value)
          }
          selected_option={selectedWheelAlignment}
          options={[
            { name: "4x2", value: "4x2" },
            { name: "6x4", value: "6x4" },
            { name: "8x4", value: "8x4" },
          ]}
        />
      );
    }
    return elements_to_render;
  };
  const buildMaintenanceServices = () => {
    let services = {} as any;
    selectedServices.map((service, index)=> {
      if(service === "tyres" && selectedAxle.length === 0) {
        services['tyres'] = {
            "tyre_width": tyreWidth,
            "tyre_serie": tyreSerie,
            "tyre_rim_diameter": tyreRimDiameter,
            "tyre_brand": tyreBrand,
            "tyre_load": tyreLoad,
            "tyre_speed_index": tyreSpeedRating,
            "tyre_type": tyreType,
          };
          return;
      }
      if(service === "retreaded-tyres" && selectedAxleRetreaded.length === 0) {
        services['retreaded-tyres'] = {
            "tyre_width": tyreWidth,
            "tyre_serie": tyreSerie,
            "tyre_rim_diameter": tyreRimDiameter,
            "tyre_brand": tyreBrand,
            "tyre_load": tyreLoad,
            "tyre_speed_index": tyreSpeedRating,
            "tyre_type": tyreType,
          };
          return;
      }
      if(service === "retreaded-tyres") {
        // return {
        //   "tyres": selectedAxle,
        // };
        let tyres = selectedAxleRetreaded.map((axle, index)=> {
          let tyre = vehicle.tyres.find((tyre: any) => tyre.position === axle);
          if (tyre.description){
            return {
              "tyre_position": tyre.position,
              "id": tyre.id,
              "tyre_brand": tyre.tyre_brand,
              "description": tyre.description,
            }
          }else{
            // return tyre.tyre_width + "/" + tyre.tyre_serie + " R" + tyre.tyre_rim_diameter + " " + tyre.tyre_brand + " " + tyre.tyre_load + " " + tyre.tyre_speed_rating + " " + tyre.tyre_type;
            return {
              "tyre_width": tyre.width,
              "tyre_serie": tyre.serie,
              "tyre_rim_diameter": tyre.rim_diameter,
              "tyre_brand": tyre.tyre_brand,
              "tyre_load": tyre.load_index_1,
              "tyre_speed_index": tyre.speed_index_1,
              "tyre_type": tyre.season,
              "tyre_position": tyre.position,
              "id": tyre.id
            }
          }
        });
        services['retreaded-tyres'] = tyres;
        return;
      }
      if(service === "tyres") {
        // return {
        //   "tyres": selectedAxle,
        // };
        let tyres = selectedAxle.map((axle, index)=> {
          let tyre = vehicle.tyres.find((tyre: any) => tyre.position === axle);
          if (tyre.description){
            return {
              "tyre_position": tyre.position,
              "id": tyre.id,
              "tyre_brand": tyre.tyre_brand,
              "description": tyre.description,
            }
          }else{
            // return tyre.tyre_width + "/" + tyre.tyre_ serie + " R" + tyre.tyre_rim_diameter + " " + tyre.tyre_brand + " " + tyre.tyre_load + " " + tyre.tyre_speed_rating + " " + tyre.tyre_type;
            return {
              "tyre_width": tyre.width,
              "tyre_serie": tyre.serie,
              "tyre_rim_diameter": tyre.rim_diameter,
              "tyre_brand": tyre.tyre_brand,
              "tyre_load": tyre.load_index_1,
              "tyre_speed_index": tyre.speed_index_1,
              "tyre_type": tyre.season,
              "tyre_position": tyre.position,
              "id": tyre.id
            }
          }
        });
        services['tyres'] = tyres;
        return;
      }
      
      if(service === "retreaded-tyres") {
        services['retreaded-tyres'] = selectedAxleRetreaded;
        return;
      }
      if(service === "wheel-alignment") {
        services["wheel-alignment"]= selectedWheelAlignment;
        return;
      }
      if(service === "brake-pads") {
        services["brake-pads"] = selectedBrakePads;
        return;
      }
      if(service === "brake-discs") {
        services["brake-discs"]= selectedBrakeDisks;
        return;
      }
      if(service === "wiper-blades") {
        services["wiper-blades"] = selectedWiperBlades;
        return;
      }
      if(service === "shock-absorbers") {
        
        services["shock-absorbers"] = selectedShockAbsorbers;
        return;
      }
      services[service]= true
      return;
    });
    return services;
  }

  const handleServiceRequest = () => {
    let maintenance_services = buildMaintenanceServices();
    console.log(selectedDealer);
    createMaintenanceRequest(
      access_token,
      vehicle.id,
      note,
      "maintenance",
      vehicleTrailer,
      maintenance_services,
      owner_email,
      date,
      selectedDealer.id,
      vehicle.account_id
    ).then((response) => {
      console.log(response);
      setLoading(false);
      navigate("/thank-you");
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    });

  };
  const renderVehicleDetails = () => {
    return (
      <Fragment>
        <div className="col-md-8 col-12 offset-md-2">
          <div>
            <VehicleDetails
              title="Vehicle details"
              type={vehicle.type.label}
              latitude={vehicle.latitude}
              longitude={vehicle.longitude}
              license_plate={vehicle.license_plate}
            />
            <h3>Find a dealer for your next maintenance</h3>
            {TRUCK_TYPES.includes(vehicle.type.slug) ||
            TRAILER_TYPES.includes(vehicle.type.slug) || HEAVY_VEHICLES.includes(vehicle.type.slug) ? (
              <TruckOrTrailer
                vehicleTrailer={vehicleTrailer}
                setVehicleTrailer={setVehicleTrailer}
              />
            ) : null}
            {renderGooglePlacesAutocomplete()}
            {renderDealers()}

            {selectedDealer !== null && selectedDealer !== undefined ? (
              <MaintenanceServices
                selectedServices={selectedServices}
                handleSelectedServices={handleSelectedServices}
                maintenanceTypes={maintenanceTypes}
                title={"Select services"}
                typeOfVehicle={typeOfVehicle}
              />
            ) : null}
            {TRUCK_TYPES.includes(vehicle.type.slug) ||
            TRAILER_TYPES.includes(vehicle.type.slug) || HEAVY_VEHICLES.includes(vehicle.type.slug) ? renderExtraServicesHeavyVehicles() : renderExtraServices()}
            {selectedDealer !== null &&
            selectedDealer !== undefined &&
            !showScheduler &&
            date === null && selectedServices.length>0? (
              <div className="row action-row mt-40">
                <div className="col-12">
                  
                  <div className="d-flex justify-content-center">
                    <button
                      onClick={() => setShowScheduler(true)}
                      className="btn btn-primary"
                    >
                      Choose date and time
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
            {showScheduler && date === null ? (
              <DayTimePickerWrapper
                className="mt-20"
                theme={schedulerTheme}
                timeSlotSizeMinutes={30}
                timeSlotValidator={timeSlotValidator}
                onConfirm={handleScheduled}
              />
            ) : null}

            {date !== null ? (
              <div className="mt-20">
                <h3>Selected date and time</h3>
                <p>
                  {date.toString()}{" "}
                  <span
                    onClick={() => {
                      setDate(null);
                      setShowScheduler(true);
                    }}
                    className="color-primary cursor-pointer text-decoration-underline"
                  >
                    Change
                  </span>
                </p>
                <div className="form-group mt-40">
                  <label htmlFor="note">Note to the maintenance provider</label>
                  <textarea
                    name="note"
                    className="form-control"
                    onChange={(e: any) => {
                      setNote(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group mt-40">
                  <label htmlFor="owner_email">Email to be used for booking follow-up</label>{" "}
                  <input
                    type="email"
                    name="owner_email"
                    className="form-control"
                    onChange={(e: any) => {
                      setOwnerEmail(e.target.value);
                    }}
                  />
                </div>
                <p className="text-small">
                When you choose this service , you consent that the license plate and certain personal data of the driver/owner of the vehicle will be shared with Bridgestone NV/SA or any of its affiliates and associated companies for the purposes of providing the maintenance booking functionality.
                </p>
                <div className="form-group text-center action-row">
                  <button
                    type="submit"
                    className="btn btn-block btn-primary color-white mt-12"
                    disabled={loading}
                    onClick={handleServiceRequest}
                  >
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Schedule my maintenance</span>
                  </button>
                  {message && (
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {message}
                    </div>
                  </div>
                )}
                </div>
                {/* <p>
                  <b>Note</b> : by sending your request, you authorize us to
                  send data to external parties in relation with your request.
                  Details are visible in our T&C here
                </p> */}
                
              </div>
            ) : null}
          </div>
        </div>
      </Fragment>
    );
  };
  return (
    <Layout
      menu={false}
      button={true}
      button_text={"Return to Webfleet"}
      button_link={"https://live-wf.webfleet.com/web/index.html#/map"}
    >
      {vehicle ? renderVehicleDetails() : <div>Loading...</div>}
    </Layout>
  );
};
export default Maintenance;
