import React, { Fragment, useEffect, useRef, useState } from "react";
import Layout from "../../components/Layout/Layout";
import { useQuery } from "../../utils/helpers";
import Maintenance from "./ServiceTypes/Maintenance";
import RoadsideAssistance from "./ServiceTypes/RoadsideAssistance";



const MakeARequest: React.FC = () => {
  let query = useQuery();

  const service_type = query.get("service_type");

  return (
    <Fragment>
      {service_type === "roadside_assistance" ? <RoadsideAssistance /> : null}
      {service_type === "maintenance" ? <Maintenance /> : null}
      {service_type !== "maintenance" && service_type !== "roadside_assistance" ? <Layout><h3>Service type is not know to our system, please try roadside_assistance or maintenance</h3></Layout>: null}
      
    </Fragment>
  );
};
export default MakeARequest;
