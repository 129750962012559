import axios from "axios";
let API_URL = "";
if(process.env.NODE_ENV === 'development') {
  API_URL = "http://api.service-bridge.test/api/auth/";
} else {
  API_URL = "https://service-bridge.eastus.cloudapp.azure.com:8443/api/auth/";
}
export const register = (name: string, email: string, password: string, password_confirmation:string, phone_number:string, country_slug:string, language_slug:string, invite_token:string) => {
  return axios.post(API_URL + "register", {
    name,
    email,
    password,
    password_confirmation,
    phone_number,
    country_slug,
    language_slug,
    invite_token
  }).then((response) => {
    console.log(response)
      if (response.data.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
      }
      return response.data;
    });
};

export const login = (account:string|null, email: string, password: string) => {
  return axios
    .post(API_URL + "login", {
      account,
      email,
      password,
    })
    .then((response) => {
    //  console.log(response)
      if (response.data.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data.data));
      }
      return response.data;
    });
};

export const logout = () => {
  localStorage.removeItem("user");
};

export const getCurrentUser = () => {
  const userStr = localStorage.getItem("user");
  // console.log('inside getCurrentUser');
  if (userStr){
    let user = JSON.parse(userStr);
    if(new Date().toISOString() < user.valid_token){
      return user;
    }
  } 
  return null;
};