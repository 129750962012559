import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import { logout } from "../../services/auth.service";
import { useNavigate } from "react-router-dom";

const Logout: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        logout();
        return navigate('/');
    } , []);
    return (
        <></>
    );
};
export default Logout;
