// import DayTimePicker from "../../lib/react-day-time-picker/src";
import DayTimePicker from "@mooncake-dev/react-day-time-picker";
function DayTimePickerWrapper({
  timeSlotSizeMinutes,
  onConfirm,
  timeSlotValidator,
  theme,
  className
}) {
    let currentTime = new Date();
    let days_to_add = 14;
    let available_date = new Date(currentTime.setDate(currentTime.getDate() + days_to_add));


    
  return (
    <div className={`day-time-picker ${className}`}>
      <DayTimePicker
        timeSlotSizeMinutes={timeSlotSizeMinutes}
        onConfirm={onConfirm}
        timeSlotValidator={timeSlotValidator}
        theme={theme}
        isDone= {false}
        isLoading= {false}
      />
      <p className="color-primary text-decoration-underline text-center">First available timeslot is on {available_date.toDateString()} at 08:00 AM</p>
    </div>
  );
}

export default DayTimePickerWrapper;
