import React, { Fragment, useEffect, useState } from "react";
import { getCurrentUser } from "../../services/auth.service";
import Layout from "../../components/Layout/Layout";
import { useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "../../utils/helpers";
import {
  getService,
  getServiceRequestPerAccessToken,
} from "../../services/user.service";
import { LanguageInterface } from "../../interfaces/LanguageInterface";
import VehicleDetails from "../../components/VehicleDetails/VehicleDetails";

const SingleService: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [service, setService] = useState<any>();
  const [languages, setLanguages] = useState<LanguageInterface[]>([]);
  let query = useQuery();
  const access_token = query.get("access_token");
  const logged_in = getCurrentUser() !== null;

  const { id } = useParams();

  useEffect(() => {
    if (localStorage.languages) {
      setLanguages(JSON.parse(localStorage.languages));
    }
    // if (process.env.NODE_ENV==='production' && !document.referrer.includes('webfleet.com')){
    //   return navigate("/");
    // }
    if (id && logged_in) {
    //   return navigate("/services");
    // } else {
      getService(id)
        .then((res) => {
          setService(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          return navigate("/");
        });
      }else if(access_token !== null && id !== null && id !== undefined) {
        getServiceRequestPerAccessToken(id, access_token).then((res) => {
          setService(res.data);
        })
        .catch((err) => {
          return navigate("/");
        });
      }
      else{
        return navigate("/");
      }
  }, [access_token, id, logged_in, navigate]);

  const renderRoadsideAssistance = () => {
    return(
      <Fragment>
  <h3>Damaged tyre</h3>
            <div className="card">
            <div className="card-body">
            <div className="row">
              <div className="col-md-4 col-12">
                <div className="form-group">
                  <label className="service-label" htmlFor="tyre_width">Tyre Width</label>
                  <p>{service.roadside_assistance.tyre_width}</p>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="form-group">
                  <label className="service-label" htmlFor="tyre_serie">Tyre Ratio</label>
                  <p>{service.roadside_assistance.tyre_serie}</p>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="form-group">
                  <label className="service-label" htmlFor="tyre_rim_diameter">Tyre Rim Diameter</label>
                  <p>{service.roadside_assistance.tyre_rim_diameter}</p>
                </div>
              </div>
              {service.roadside_assistance.tyre_speed_rating !== null ? (
              <div className="col-md-4 col-12">
                <div className="form-group">
                  <label className="service-label" htmlFor="tyre_load">Tyre Load</label>
                  <p>{service.roadside_assistance.tyre_load}</p>
                </div>
              </div>
              ) : null}
              {service.roadside_assistance.tyre_speed_rating !== null ? (
              <div className="col-md-4 col-12">
                <div className="form-group">
                  <label className="service-label" htmlFor="tyre_speed_rating">Tyre Speed Rating</label>
                  <p>{service.roadside_assistance.tyre_speed_rating}</p>
                </div>
              </div>
              ): null}
              {service.roadside_assistance.tyre_type !== null ? (
              <div className="col-md-4 col-12">
                <div className="form-group">
                  <label className="service-label" htmlFor="tyre_type">Tyre Type</label>
                  <p>{service.roadside_assistance.tyre_type}</p>
                </div>
              </div>
              ) : null}
              {service.roadside_assistance.tyre_ean !== null ? (
                <div className="col-md-4 col-12">
                <div className="form-group">
                  <label className="service-label" htmlFor="tyre_type">Tyre EAN</label>
                  <p>{service.roadside_assistance.tyre_ean}</p>
                </div>
              </div>) : null}
              {service.roadside_assistance.tyre_ipc !== null ? (
                <div className="col-md-4 col-12">
                <div className="form-group">
                  <label className="service-label" htmlFor="tyre_type">Tyre IPC</label>
                  <p>{service.roadside_assistance.tyre_ipc}</p>
                </div>
                </div>) : null}
              {service.roadside_assistance.tyre_position !== null ? (
                <div className="col-md-4 col-12">
                <div className="form-group">
                  <label className="service-label" htmlFor="tyre_type">Tyre Position</label>
                  <p>{service.roadside_assistance.tyre_position}</p>
                  </div>
                </div>) : null}
                {service.roadside_assistance.tyre_brand !== null ? (
                <div className="col-md-4 col-12">
                <div className="form-group">
                  <label className="service-label" htmlFor="tyre_type">Tyre Brand</label>
                  <p>{service.roadside_assistance.tyre_brand}</p>
                  </div>
                </div>) : null}

                {service.roadside_assistance.tyre_steer_or_drive !== null && service.roadside_assistance.tyre_steer_or_drive!=="-" ? (
                <div className="col-md-4 col-12">
                <div className="form-group">
                  <label className="service-label" htmlFor="tyre_type">Tire pattern</label>
                  <p>{service.roadside_assistance.tyre_steer_or_drive === "steer" ? "Steer":"Drive"}</p>
                  </div>
                </div>) : null}
                
                
                <div className="col-md-4 col-12">
                <div className="form-group">
                  <label className="service-label" htmlFor="tyre_type">Vehicle type </label>
                  <p>{service.roadside_assistance.trailer ? "Trailer":"Truck"}</p>
                  </div>
                </div>
                {service.roadside_assistance.tyre_description !== null ? (
                <div className="col-12">
                <div className="form-group">
                  <label className="service-label" htmlFor="tyre_description">Tyre Description</label>
                  <p>{service.roadside_assistance.tyre_description}</p>
                  </div>
                </div>) : null}
                {service.roadside_assistance.note !== null ? (
                <div className="col-12">
                <div className="form-group">
                  <label className="service-label" htmlFor="note">Note</label>
                  <p>{service.roadside_assistance.note}</p>
                  </div>
                </div>) : null}
                
            </div>
            </div>
          </div>
          <h3>Contact person details</h3>
          <div className="card">
            <div className="card-body">
          <div className="row">
          <div className="form-group col-md-4 col-12">
            <label className="service-label" htmlFor="driver_name">Driver name</label>
            <p>{service.driver_name}</p>
          </div>
          <div className="form-group col-md-4 col-12">
            <label className="service-label" htmlFor="driver_phone">Driver phone</label>
            <p>{service.driver_phone}</p>
          </div>
          <div className="form-group col-md-4 col-12">
            <label className="service-label" htmlFor="language">Language</label>
            <p>{languages.filter(language=>language.value === service.driver_language).map(language=>language.label)[0]}</p>
          </div>
          </div>
          </div>
          </div>
      </Fragment>
    )
  }

  const renderServiceDetails = () => {
    if (service.type.slug === "roadside-assistance") {
      return renderRoadsideAssistance();
    } else if(service.type.slug === "maintenance") {
      return renderMaintenance();
    }else{
      return <h2>Not supported service type, please contact administrator</h2>
    }
  };


  const renderMaintenance = () => {
    return(
      <Fragment>
        {renderDealerDetails()}

        <h3>Service details</h3>
        <p>
        Owner email: <b>{service.maintenance.owner_email}</b>
        </p>
        <p>
          Requested date: <b>{service.maintenance.requested_date}</b>
        </p>
        <div className="row">
          {renderMaintenanceServices()}
        </div>

      </Fragment>
    )
  }
  const renderMaintenanceServicesFields = (services:any) => {
    return(
      <Fragment>
        {/* table view with service data */}
        <table>
          <thead>
            <tr>
              <th>Service</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(services).map((service:any, index:number) => {
              if(service === "tyres" || service === "retreaded-tyres") {
                return renderTyres(services, service);
              }
              return(
              <tr key={index}>  
                <td><b>{services[service].label}</b></td>
                <td>{services[service].description}</td>
              </tr>
            )}
            )}
          </tbody>
        </table>
      </Fragment>
    )
  }
  const renderMaintenanceServices = () => {
    let services = {} as any;
    service.maintenance_data.maintenance_types.map((maintenance_service:any, index:number) => {
      if(maintenance_service.slug === "tyres" || maintenance_service.slug === "retreaded-tyres") {
        let tyre = JSON.parse(maintenance_service.pivot.description);

        // return renderTyres(maintenance_service);
        if(services[maintenance_service.slug] && services[maintenance_service.slug].length > 0) {
          services[maintenance_service.slug].push({"label":maintenance_service.label, "description":tyre});
        }else{
          services[maintenance_service.slug] = [{"label":maintenance_service.label, "description":tyre}];
        }
      }else if(["wheel-alignment", "brake-pads", "brake-discs", "wiper-blades", "shock-absorbers"].includes(maintenance_service.slug)) {
        // return renderMaintenanceServiceWithOption(maintenance_service);
        services[maintenance_service.slug] = {"label":maintenance_service.label, "description":maintenance_service.pivot.description};
      }else{
        services[maintenance_service.slug] = {"label":maintenance_service.label, "description":"requested"};
      }
    });
    return renderMaintenanceServicesFields(services);
  }

  const renderTyres = (services:any, service:any) => {
    return services[service].map((tyre:any, index:number) => {
      if(tyre.description.description){
        return(
          <tr key={index}>
            <td><b>{tyre.label}</b></td>
            <td>{`${tyre.description.position ? tyre.description.position+" - " :""} ${tyre.description.tyre_brand}:${tyre.description.description}`}</td>
          </tr>
        )
      }else{
        return(

        <tr key={index}>
            <td><b>{tyre.label}</b></td>
            <td>{`${tyre.description.position ? tyre.description.position+" - " :""} ${tyre.description.tyre_brand}:${tyre.description.tyre_width + "/" + tyre.description.tyre_serie + " R" + tyre.description.tyre_rim_diameter + " " + tyre.description.tyre_brand + " " + tyre.description.tyre_load + " " + tyre.description.tyre_speed_index + " " + tyre.description.tyre_type}`}</td>
          </tr>
        )
      }
  })
  }

  const renderDealerDetails = () => {
    let selectedDealer = service.maintenance_data.dealer;
    return (
      <div className="row align-self-center">
        <div>
          <h3>Dealer</h3>
        </div>
        <div>
          <h4 className="m-0">{selectedDealer.name}</h4>
          <p className="m-0">
            <i>{selectedDealer.brand}</i>
          </p>
        </div>
        <div>
          <p>
            {selectedDealer.address ? `${selectedDealer.address},` : null}{" "}
            {selectedDealer.city}
          </p>
          <p>
            {selectedDealer.zip ? `${selectedDealer.zip},` : null}{" "}
            {selectedDealer.country}
          </p>
          <p>
            <a
              className="btn btn-primary mr-8"
              target="_blank"
              href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${selectedDealer.latitude.replace(
                ",",
                "."
              )},${selectedDealer.longitude.replace(",", ".")}`}
            >
              Show route
            </a>
            <a
              className="btn btn-outline-primary"
              href={`mailto:${selectedDealer.email}`}
            >
              Email
            </a>
          </p>
        </div>
      </div>
    );
  }
  const renderVehicleDetails = () => {
    return (
      <div className="col-md-8 col-12 offset-md-2">
        <div>
          {logged_in ? (
          <Link to="/services" className="btn width-100 btn-outline-primary"> Back to services</Link>
          ) : <button className="btn width-100 btn-outline-primary" onClick={() => navigate(-1)}> Back to services</button>}
          <h1>Service details</h1>
          <p>
            <b>Status:</b> {service.status.label}
          </p>
          <p>
            <b>Type:</b> {service.type.label}
          </p>
          <VehicleDetails
              title="Your vehicle details"
              type={service.vehicle_type}
              license_plate={service.vehicle_license_plate}
            />
          {renderServiceDetails()}
        </div>
      </div>
    );
  };


  if(logged_in){
      return     <Layout>{service && !loading ? renderVehicleDetails() : <div>Loading...</div>}</Layout>
    }else{
      return <Layout menu={false} button={true} button_text={"Return to Webfleet"} button_link={"https://live-wf.webfleet.com/web/index.html#/map"}>{service && !loading ? renderVehicleDetails() : <div>Loading...</div>}</Layout>
  }
};
export default SingleService;
