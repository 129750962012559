import * as React from "react";
import MaintenanceServiceCard from "../MaintenanceServiceCard/MaintenanceServiceCard";

type IProps = {
    title: string;
    maintenanceTypes: any[]; 
    handleSelectedServices: (service: any) => void;
    selectedServices: any[];
    typeOfVehicle: string;
};

const MaintenanceServices: React.FC<IProps> = ({
  title,
  maintenanceTypes,
  handleSelectedServices,
  selectedServices,
  typeOfVehicle
}) => {
  return (
    <React.Fragment>
      <h3>Services</h3>
      <div className="row">
      {maintenanceTypes.map((service:any, index:number) => {
        if(service.type === typeOfVehicle){
        return(
          <MaintenanceServiceCard handleSelectedServices={handleSelectedServices} selectedServices={selectedServices} service={service} />
        )
        }
      })}
      </div>
    </React.Fragment>
  );
};

export default MaintenanceServices;
