import React, { Fragment, useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Layout from "../../components/Layout/Layout";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { createVehicle, extractVehicleData, getAllCountries, getAllVehicleTypes } from "../../services/user.service";
import Select from "react-select";
import { TyreInterface } from "../../interfaces/TyreInterface";
import { getCurrentUser } from "../../services/auth.service";

const CreateVehicle: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const navigate = useNavigate();
    const [country, setCountry] = useState<string>("");
    const [vehicleData, setVehicleData] = useState<any>({});
    const params = useParams();
    const [countries, setCountries] = useState<any[]>([]);
    const [types, setTypes] = useState<any[]>([]);

    const [fleet_reference, setFleetReference] = useState<string>("");
    const [layout_code, setLayoutCode] = useState<string>("");
    const [license_plate_webfleet, setLicensePlateWebFleet] = useState<string>("");

    const [location_id, setLocationId] = useState<string>("");
    const [location_name, setLocationName] = useState<string>("");
    const [odometer_reading, setOdometerReading] = useState<string>("");
    const [vin, setVin] = useState<string>("");
    const [fleetbridge_vehicle_id, setFleetbridgeVehicleId] = useState<string>("");
    const [object_uid_webfleet, setObjectUidWebfleet] = useState<string>("");
    const [type, setType] = useState<string>("");
    const [tyres, setTyres] = useState<TyreInterface[]>([]);
    const [latitude, setLatitude] = useState<string>("");
    const [longitude, setLongitude] = useState<string>("");
    const [latitude_mdeg, setLatitudeMdeg] = useState<string>("");
    const [longitude_mdeg, setLongitudeMdeg] = useState<string>("");
    const [position_text, setPositionText] = useState<string>("");
    const [label] = useState<string>("");
    const [countryVechicle, setCountryVechicle] = useState<string>("");
    const [note] = useState<string>("");
    const [driver_name, setDriverName] = useState<string>("");
    const [driver_phone, setDriverPhone] = useState<string>("");
    const [tyres_db, setTyresDb] = useState<any>([]);

    const [extractedInformation, setExtractedInformation] = useState<boolean>(false);
  useEffect(() => {
    if (localStorage.countries) {
      setCountries(JSON.parse(localStorage.countries));
    }else{
      getAllCountries().then((res)=>{
        setCountries(JSON.parse(localStorage.countries));
        }
      )};
      if (localStorage.vehicle_types) {
        setTypes(JSON.parse(localStorage.vehicle_types));
      }else{
        getAllVehicleTypes().then((res)=>{
          setTypes(JSON.parse(localStorage.vehicle_types));
          }
        )};
  }, []);

  const initialValues: {
    license_plate: string;
    license_plate_webfleet: string;
  } = {
    license_plate: "",
    license_plate_webfleet: "",
  };
  const validationSchema = Yup.object().shape({
    license_plate: Yup.string().nullable(),
    license_plate_webfleet: Yup.string().required("This field is required!"),
  });

  const initialValuesCreateVehicle: {
    fleet_reference: string;
    layout_code: string;
    license_plate_webfleet: string;
    location_id: string;
    location_name: string;
    odometer_reading: string;
    vin: string;
    fleetbridge_vehicle_id: string;
    object_uid_webfleet: string;
    type: string;
    tyres: TyreInterface[],
    latitude: string;
    longitude: string;
    latitude_mdeg: string;
    longitude_mdeg: string;
    position_text: string;
    label: string;
    country: string;
    note: string;
    driver_name: string;
    driver_phone: string;
  } = {
    fleet_reference: fleet_reference,
    layout_code: layout_code,
    license_plate_webfleet: license_plate_webfleet,
    location_id: location_id,
    location_name: location_name,
    odometer_reading: odometer_reading,
    vin: vin,
    fleetbridge_vehicle_id: fleetbridge_vehicle_id,
    object_uid_webfleet: object_uid_webfleet,
    type: type,
    tyres: tyres,
    latitude: latitude,
    longitude: longitude,
    latitude_mdeg: latitude_mdeg,
    longitude_mdeg: longitude_mdeg,
    position_text: position_text,
    label: label,
    country: country,
    note: note,
    driver_name: driver_name,
    driver_phone: driver_phone,
  }
    const validationSchemaCreateVehicle = Yup.object().shape({
        fleet_reference: Yup.string().nullable(),
        layout_code: Yup.string().nullable(),
        license_plate_webfleet: Yup.string().required("This field is required!"),
        location_id: Yup.string().nullable(),
        location_name: Yup.string().nullable(),
        odometer_reading: Yup.string().nullable(),
        vin: Yup.string().nullable(),
        fleetbridge_vehicle_id: Yup.string().nullable(),
        object_uid_webfleet: Yup.string().required("This field is required!"),
        type: Yup.string().required("This field is required!"),
        tyres: Yup.array().nullable(),
        latitude: Yup.string().nullable(),
        longitude: Yup.string().nullable(),
        latitude_mdeg: Yup.string().nullable(),
        longitude_mdeg: Yup.string().nullable(),
        position_text: Yup.string().nullable(),
        label: Yup.string().nullable(),
        country: Yup.string().required("This field is required!"),
        note: Yup.string().nullable(),
        driver_name: Yup.string().nullable(),
        driver_phone: Yup.string().nullable(),
    });

    const handleCreateVehicle = (formValue: {
        fleet_reference: string;
        layout_code: string;
        license_plate_webfleet: string;
        location_id: string;
        location_name: string;
        odometer_reading: string;
        vin: string;
        fleetbridge_vehicle_id: string;
        object_uid_webfleet: string;
        type: string;
        tyres: TyreInterface[],
        latitude: string;
        longitude: string;
        latitude_mdeg: string;
        longitude_mdeg: string;
        position_text: string;
        label: string;
        country: string;
        note: string;
        driver_name: string;
        driver_phone: string;
      }) => {
        let account_id = params.id ? params.id : getCurrentUser().account_id;
        const { license_plate_webfleet, fleet_reference, layout_code, location_id, location_name, odometer_reading, 
        vin, fleetbridge_vehicle_id, object_uid_webfleet, type, tyres, latitude, longitude, latitude_mdeg, longitude_mdeg, position_text,
        label, country, note, driver_name, driver_phone } = formValue;
        setMessage("");
        setLoading(true);
          createVehicle(
            license_plate_webfleet,
            fleet_reference,
            fleetbridge_vehicle_id,
            label,
            latitude,
            latitude_mdeg,
            layout_code,
            location_id,
            location_name,
            longitude,
            longitude_mdeg,
            object_uid_webfleet,
            odometer_reading,
            position_text,
            type,
            tyres,
            vin,
            country,
            note,
            driver_name,
            driver_phone,
            tyres_db,
            account_id,
          ).then(
            (res) => {
              if(getCurrentUser().super_admin) {
                navigate('/accounts/' + account_id);
              } else {
                navigate('/dashboard');
              }
              setLoading(false);
            },
            (error) => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.error.message) ||
                  error.message ||
                error.toString();
              console.log(error);
              setLoading(false);
              setMessage(resMessage);
            }
          );
      };
  const handleExtractInfo = (formValue: {
    license_plate: string;
    license_plate_webfleet: string;
  }) => {
    const { license_plate, license_plate_webfleet } = formValue;
    setMessage("");
    setTyres([]);
    setLoading(true);
    if (params.id) {
      extractVehicleData(
        license_plate,
        license_plate_webfleet,
        country,
        params.id
      ).then(
        (res) => {
          setVehicleData(res.data)
          setLoading(false);
          setExtractedInformation(true);
          if(res.data.fleetbridge !== null && res.data.fleetbridge !== undefined && res.data.fleetbridge.length > 0){
            setFleetReference(res.data.fleetbridge[0].FleetReference);
            setLayoutCode(res.data.fleetbridge[0].LayoutCode);
            setLocationId(res.data.fleetbridge[0].LocationId);
            setLocationName(res.data.fleetbridge[0].LocationName);
            setFleetbridgeVehicleId(res.data.fleetbridge[0].VehicleId);
            setTyres(res.data.fleetbridge[0].Assets.Tyres);
            setCountryVechicle(res.data.fleetbridge[0].Country);
            setTyresDb(res.data.fleetbridge[1]);
        }else{
            setFleetReference("");
            setLayoutCode("");
            setLocationId("");
            setLocationName("");
            setFleetbridgeVehicleId("");
            setTyres([]);
            setTyresDb([]);
        }
        if(res.data.webfleet !== undefined && res.data.webfleet !== null && Object.keys(res.data.webfleet).length > 0){
            setObjectUidWebfleet(res.data.webfleet.object_data[0].objectuid);
            setType(res.data.webfleet.object_data[0].objecttype);
            setLatitude(res.data.webfleet.object_data[0].latitude);
            setLongitude(res.data.webfleet.object_data[0].longitude);
            setLatitudeMdeg(res.data.webfleet.object_data[0].latitude_mdeg);
            setLongitudeMdeg(res.data.webfleet.object_data[0].longitude_mdeg);
            setPositionText(res.data.webfleet.object_data[0].postext);
            setOdometerReading(res.data.webfleet.object_data[0].odometer);
            setLicensePlateWebFleet(res.data.webfleet.vehicle_data[0].licenseplatenumber);
            setVin(res.data.webfleet.vehicle_data[0].identnumber);

            setDriverName(res.data.webfleet.object_data[0].drivername);
            setDriverPhone(res.data.webfleet.object_data[0].drivertelmobile);
        }else{
            setObjectUidWebfleet("");
            setType("");
            setLatitude("");
            setLongitude("");
            setLatitudeMdeg("");
            setLongitudeMdeg("");
            setPositionText("");
            setOdometerReading("");
            setDriverName("");
            setDriverPhone("");
            setVin("");
            setLicensePlateWebFleet("");
        }
       
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.error.message) ||
            error.message ||
            error.toString();
          console.log(error);
          setLoading(false);
          setMessage(resMessage);
        }
      );
    }
  };

  const renderField = (name:string, label:string, type:string = "text", value:string = "", manualEntry:boolean = false) => {
    if((value!=="" && value!==undefined && value!==null) || manualEntry){
      return (
        <div className="col-md-6 mb-8 p-3">
          <div className="form-group">
            <label htmlFor={name}>{label}</label>
            <Field name={name} type={type} className="form-control" disabled={extractedInformation && !manualEntry ? true:false}/>
            <ErrorMessage name={name} component="div" className="alert alert-danger" />
          </div>
        </div>
      );
    }
  }
  const renderTyresRow = (tyres: TyreInterface[]) => {
    if(vehicleData.fleetbridge !== null && vehicleData.fleetbridge !== undefined && vehicleData.fleetbridge.length > 0){
    return tyres.map((tyre:any, index:any) => {
        let tyre_description = tyres_db.hasOwnProperty(tyre.EAN) ? tyres_db[tyre.EAN].description : "";
        if(tyre_description !== ""){
          tyre_description = tyre_description.split(/(\s{1,}-\s{1,})|\s{2,}/);
          if(tyre_description.length > 1){
            tyre_description = `${tyre_description[0]}\n${tyre_description[2]}`;
          }
        }
        return (
          <div className="col-3 col-md-3 mb-8 p-3" key={index}>
          <div
            className={`card mouse-pointer tyre-card`}>
            {/* <div className="card-header">
                <h5 className="card-title">Tyre {tyre.Position} - {tyre.TyreBrand}</h5>
            </div>
            <div className="card-body">
                <p><b>EAN</b>: <i>{tyre.EAN}</i></p>
                {tyre_description !== "" ?  <p><b>Description</b>: <i style={{whiteSpace:"pre-line"}}>{tyre_description}</i></p>
 :  <Fragment> 
                <p><b>Rim Diameter</b>: <i>{tyre.RimDiameter}</i></p>
                <p><b>Serie</b>: <i>{tyre.Serie}</i></p>
                <p><b>Width</b>: <i>{tyre.Width}</i></p></Fragment>
            }
            </div> */}
          </div>
        </div>
        );
      });
    }else{
        <p>No tyres found</p>
    }
  }

  const renderTyres = () => {
    let tyres = {} as any;
    if(vehicleData.fleetbridge[0].Assets.Tyres === null || vehicleData.fleetbridge[0].Assets.Tyres === undefined){
      return <p>No tyres found</p>;
    }
    vehicleData.fleetbridge[0].Assets.Tyres.forEach((tyre: any) => {
      if (tyres[tyre.AxlePosition] !== undefined) {
        tyres[tyre.AxlePosition].push(tyre);
      } else {
        tyres[tyre.AxlePosition] = [tyre];
      }
    });
    tyres = sortTyres(tyres);
    return Object.keys(tyres).map((key: string) => {
      return (
        <div className="row justify-content-between tyres-row" key={key}>
          {renderTyresRow(tyres[key])}
        </div>
      );
    });
  };

  const sortTyres = (tyres:any) => {
    Object.keys(tyres).forEach((key) => {
      let leftTyres = [] as any;
      let rightTyres = [] as any;
      tyres[key].map((tyre: any) => {
        if(tyre.Position.indexOf("L") !== -1) {
          leftTyres.push(tyre);
        }
        if(tyre.Position.indexOf("R") !== -1) {
          rightTyres.push(tyre);
        }
        return tyre;
      });
      leftTyres.sort(function(a:any, b:any) {
        return a.Position.localeCompare(b.Position);
      });
      rightTyres.sort(function(a:any, b:any) {
        return b.Position.localeCompare(a.Position);
      });
      tyres[key] = [...leftTyres, ...rightTyres];
    });
    return tyres;
  }

  const renderTyresWrapper = () => {
    return (
      <Fragment>
        <h3>Tyres configuration</h3>
        {vehicleData.fleetbridge !== null && vehicleData.fleetbridge !== undefined && vehicleData.fleetbridge.length > 0 ? renderTyres():<p>No tyres found in our system</p>}
      </Fragment>
    );
  };
  
  return (
    <Layout>
        <>
      <div className="col-md-6 col-12 offset-md-3">
        <div>
          <h1>Add a vehicle</h1>
          <p>Use the form below to add a vehicle</p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleExtractInfo}
          >
            <Form>
              <div className="form-group">
                <label htmlFor="license_plate_webfleet">
                  License plate (as indicated in Webfleet)
                </label>
                <Field
                  name="license_plate_webfleet"
                  type="text"
                  className="form-control"
                />
                <ErrorMessage
                  name="license_plate_webfleet"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="license_plate">License plate Fleetbridge (if formatting different than Webfleet's one)</label>
                <Field
                  name="license_plate"
                  type="text"
                  className="form-control"
                />
                <ErrorMessage
                  name="license_plate"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
             

              <div className="form-group">
                <label htmlFor="country">Country</label>
                <Select
                  className="select-dropdown"
                  options={countries}
                  onChange={(value: any) => {
                    setCountry(value.value);
                  }}
                />
                <ErrorMessage
                  name="country"
                  component="div"
                  className="alert alert-danger"
                />
              </div>

              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-block btn-outline-primary"
                  disabled={loading}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Extract data</span>
                </button>
              </div>

              {message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
            </Form>
          </Formik>
        </div>
    </div>
    <Formik
            initialValues={initialValuesCreateVehicle}
            validationSchema={validationSchemaCreateVehicle}
            onSubmit={handleCreateVehicle}
            enableReinitialize={true}
          >
            <Form>
            <div className="row">
                <div className="col-12">
                    {/* {renderFleetBridgeData()} */}
                    {extractedInformation ? <Fragment><>
                    <h2>Vehicle Data</h2>
                    <div className="row">
                            {renderField("fleet_reference", "Fleet Reference", "text", fleet_reference)}
                            {renderField("layout_code", "Layout Code", "text", layout_code)}
                            {renderField("license_plate_webfleet", "License plate", "text", license_plate_webfleet)}
                            {renderField("location_id", "Location ID", "text", location_id)}
                            {renderField("location_name", "Location name", "text", location_name)}
                            {renderField("odometer_reading", "Odometer Reading", "text", odometer_reading)}
                            {renderField("vin", "VIN", "text", vin)}
                            {renderField("fleetbridge_vehicle_id", "FleetBridge Vehicle ID", "text", fleetbridge_vehicle_id)} 
                            <div className="col-md-6 mb-8 p-3">
                              <div className="form-group">
                                <label htmlFor="country">Country</label>
                                <Select
                                className="select-dropdown"
                                options={countries}
                                onChange={(value: any) => {
                                    setCountry(value.value);
                                }}
                                value={countries.filter((country:any) => country.value === countryVechicle)[0]}
                                />
                                <ErrorMessage
                                name="country"
                                component="div"
                                className="alert alert-danger"
                                />
                              </div>        
                            </div>
                            {renderField("object_uid_webfleet", "Object UID Webfleet", "text", object_uid_webfleet)}
                            {renderField("position_text", "Position Text", "text", position_text)}
                            <div className="col-md-6 mb-8 p-3">

                            <div className="form-group">
                                <label htmlFor="type">Type</label>
                                <Select
                                className="select-dropdown"
                                options={types}
                                onChange={(value: any) => {
                                    setType(value.value);
                                }}
                                value={types.filter((typeElement:any) => typeElement.value === type)[0]}
                                />
                                <ErrorMessage
                                name="type"
                                component="div"
                                className="alert alert-danger"
                                />
                            </div>       
                            </div>
                            {renderField("longitude", "Longitude", "text", longitude)}
                            {renderField("latitude", "Latitude", "text", latitude)}
                            {renderField("label", "Label", "text", label, true)}
                            {renderField("driver_name", "Driver Name", "text", driver_name, true)}
                            {renderField("driver_phone", "Driver Phone", "text", driver_phone, true)}                           
                    </div>
                    <div className="row">
                        {renderField("note", "Note", "text", note)}
  
                      </div>
                    </> 
                    <div className="row">
                        {renderTyresWrapper()}
                    </div>    
                    <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-block btn-outline-primary"
                  disabled={loading}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Add vehicle</span>
                </button> 
              </div></Fragment>: null}

              {message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
                </div>
            </div>
        </Form> 
        </Formik>
        </>
    </Layout>
  );
};
export default CreateVehicle;
