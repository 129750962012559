import { useEffect, useState } from "react";
import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { useParams } from "react-router-dom";
import { deleteUser, deleteVehicle, getAccountNonAdmin } from "../../services/user.service";
import Moment from "react-moment";
import { AccountInterface } from "../Accounts/AccountInterfeace";
import { VehicleInterface } from "../Vehicles/VehicleInterfeace";
import { UserInterface } from "../Users/UserInterface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { confirm } from "react-confirm-box";

const UserDashboard = () => {
  const [account, setAccount] = useState({} as AccountInterface);
  useEffect(() => {
    getAccountNonAdmin()
      .then((res) => {
        setAccount(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const deleteUserHandler = async (id: number) => {
    const result = await confirm(
      `Are you sure you want to delete user with id=${id}?`,
      {
        classNames: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-success",
        },
      }
    );
    if (result) {
      deleteUser(id).then((res) => {
        getAccountNonAdmin()
          .then((res) => {
            setAccount(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  };

  const deleteVehicleHandler = async (id: number) => {
    const result = await confirm(`Are you sure you want to delete vehicle with id=${id}?`, {classNames:{confirmButton: "btn btn-danger", cancelButton: "btn btn-success"}});
    if (result) {
        deleteVehicle(id).then(res => {
          getAccountNonAdmin()
            .then((res) => {
              setAccount(res.data);
            })
            .catch((err) => {
              console.log(err);
            });
        })
    }
}
  const renderAccountData = () => {
    return (
      <div className="row">
        <div className="col-md-6">
          <div className="form-group mb-8">
            <h4 className="m-0">Name</h4>
            <p className="m-0">{account.name}</p>
          </div>
          <div className="form-group mb-8">
            <h4 className="m-0">Slug</h4>
            <p className="m-0">{account.slug}</p>
          </div>
          <div className="form-group mb-8">
            <h4 className="m-0">Description</h4>
            <p className="m-0">{account.description}</p>
          </div>
          <div className="form-group mb-8">
            <h4 className="m-0">Account Number</h4>
            <p className="m-0">{account.account_number}</p>
          </div>
          <div className="form-group mb-8">
            <h4 className="m-0">Contract Account Number</h4>
            <p className="m-0">{account.contract_account_number}</p>
          </div>

          <div className="form-group mb-8">
            <h4 className="m-0">Contract Name</h4>
            <p className="m-0">{account.contract_name}</p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group mb-8">
            <h4 className="m-0">Webfleet Username</h4>
            <p className="m-0">{account.webfleet_username}</p>
          </div>
          <div className="form-group mb-8">
            <h4 className="m-0">Webfleet Password</h4>
            <p className="m-0">*********************</p>
          </div>
          <div className="form-group mb-8">
            <h4 className="m-0">Webfleet API Key</h4>
            <p className="m-0">*********************</p>
          </div>
          <div className="form-group mb-8">
            <h4 className="m-0">Created At</h4>
            <p className="m-0">
              <Moment format="YYYY/MM/DD">{account.created_at}</Moment>
            </p>
          </div>
          <div className="form-group mb-8">
            <h4 className="m-0">Updated At</h4>
            <p className="m-0">
              <Moment format="YYYY/MM/DD">{account.updated_at}</Moment>
            </p>
          </div>
        </div>
      </div>
    );
  };
  const renderAccountVehicles = () => {
    if (account.vehicles !== undefined && account.vehicles.length > 0) {
      return account.vehicles.map((vehicle: VehicleInterface) => {
        return (
          <tr key={vehicle.id}>
            <td>{vehicle.id}</td>
            <td>{vehicle.license_plate}</td>
            <td>{vehicle.fleet_reference}</td>
            <td>{vehicle.vin}</td>

            <td>{vehicle.object_uid_webfleet}</td>
            <td>
              <Moment format="YYYY/MM/DD">{vehicle.updated_at}</Moment>
            </td>
            <td className="action-2">
              <span className="btn btn-success mblock-4 mr-8">
                <Link
                  className="d-flex color-white justify-content-between align-items-center"
                  to={`/vehicles/${vehicle.id}`}
                >
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    className="d-inline mr-8"
                  />{" "}
                  Details
                </Link>{" "}
              </span>
              <span className="btn btn-danger mblock-4" onClick={() => deleteVehicleHandler(vehicle.id)}>
                <Link
                  className="d-flex color-white justify-content-between align-items-center"
                  to=""
                >
                  <FontAwesomeIcon icon={faTrash} className="d-inline mr-8" />
                  Delete
                </Link>
              </span>
            </td>
          </tr>
        );
      });
    }
  };

  const renderAccountUsers = () => {
    if (account.users !== undefined && account.users.length > 0) {
      return account.users.map((user: UserInterface) => {
        return (
          <tr key={user.id}>
            <td>{user.id}</td>
            <td>{user.email}</td>
            <td>{user.name}</td>
            <td>{user.phone_number}</td>
            <td>{user.language !== null ? user.language.label : ""}</td>
            <td>{user.country !== null ? user.country.label : ""}</td>

            <td>
              <Moment format="YYYY/MM/DD">{user.created_at}</Moment>
            </td>
            <td>
              <Moment format="YYYY/MM/DD">{user.updated_at}</Moment>
            </td>
            <td className="action-1">
              <span
                className="btn btn-danger mblock-4 mr-8"
                onClick={() => deleteUserHandler(user.id)}
              >
                <Link
                  className="d-flex color-white justify-content-between align-items-center"
                  to=""
                >
                  <FontAwesomeIcon icon={faTrash} className="d-inline mr-8" />
                  Delete
                </Link>
              </span>
            </td>
          </tr>
        );
      });
    }
  };
  return (
    <React.Fragment>
      <>
        <div className="row">
          <div className="col-12 col-md-6">
            <h2>{account.name}</h2>
          </div>
          <div className="col-12 col-md-6 text-right align-self-center">
            <Link
              to={`/accounts/${account.id}/edit`}
              className="btn btn-outline-primary"
            >
              Edit Account
            </Link>
          </div>
          <div className="col-md-12">{renderAccountData()}</div>
          <div className="col-12 col-md-6">
            <h2>Vehicles</h2>
          </div>
          <div className="col-12 col-md-6 text-right align-self-center">
            <Link
              to={`/vehicles/${account.id}/add`}
              className="btn btn-outline-primary"
            >
              Add vehicle
            </Link>
          </div>
          <div className="col-md-12">
          <div style={{overflowX:"auto"}}>

            <table className="table table-striped">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>License Plate</th>
                  <th>Fleet Reference</th>
                  <th>VIN</th>
                  <th>Object UID Webfleet</th>
                  <th>Last Updated At</th>
                  <th>More</th>
                </tr>
              </thead>
              <tbody>{renderAccountVehicles()}</tbody>
            </table>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <h2>Users</h2>
          </div>
          <div className="col-12 col-md-6 text-right align-self-center">
            <Link
              to={`/users/${account.id}/add`}
              className="btn btn-outline-primary"
            >
              Add user
            </Link>
          </div>
          <div className="col-md-12">
          <div style={{overflowX:"auto"}}>

            <table className="table table-striped">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Email</th>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>Language</th>
                  <th>Country</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  <th>More</th>
                </tr>
              </thead>
              <tbody>{renderAccountUsers()}</tbody>
            </table>
            </div>
          </div>
        </div>
      </>
    </React.Fragment>
  );
};

export default UserDashboard;
