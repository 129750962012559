import React from 'react';
import Logo from '../Logo';
import Nav from '../Nav';
import styles from "./Header.module.scss";

type IProps = {
    children? : JSX.Element,
    menu?: boolean,
    button?: boolean,
    button_link?: string,
    button_text?: string
}
const Header: React.FC<IProps> = ({children, menu, button, button_link, button_text}) =>{
    return(
        <div className={styles.headerWrapper}>
            <Logo />
            {menu !== false ? <Nav /> : null}
            {button == true ? <a href={button_link} className="btn btn-primary">{button_text}</a> : null}
        </div>
    )
}

export default Header;