import React from 'react';
// import { Link } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';

function ThankYouDealer() {
  return(
    <>
     <Layout menu={false} button={true} button_text={"Return to Webfleet"} button_link={"https://live-wf.webfleet.com/web/index.html#/map"}>
      <React.Fragment>
        <h2>Thank you for responding to our request</h2>
        <p>We will pass the information to the client.</p>
      </React.Fragment>
    </Layout>
        
    </>
  );
}

export default ThankYouDealer;