import * as React from "react";

type IProps = {
  title: string;
  dealers: any;
  setSelectedDealer: (dealer: any) => void;
  setMaintenanceTypes: (dealer: any) => void;
  dealers_per_page: number;
  selectedDealer: any;
  starting_point: any;
};

const DealersList: React.FC<IProps> = ({
  title,
  dealers,
  setSelectedDealer,
  setMaintenanceTypes,
  dealers_per_page,
  selectedDealer,
  starting_point,
}) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const renderSelectedDealerList = () => {
    return (
      <div className="row align-self-center">
        <div>
          <h3>Selected Dealer</h3>
        </div>
        <div>
          <h4 className="m-0">{selectedDealer.name}</h4>
          <p className="m-0">
            <i>{selectedDealer.brand}</i>
          </p>
        </div>
        <div>
          <p>
            {selectedDealer.address ? `${selectedDealer.address},` : null}{" "}
            {selectedDealer.city}
          </p>
          <p>
            {selectedDealer.zip ? `${selectedDealer.zip},` : null}{" "}
            {selectedDealer.country}
          </p>
          <p>
            <a
              className="btn btn-primary width-100"
              target="_blank"
              href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&origin=${
                starting_point.lat
              },${
                starting_point.lng
              }&destination=${selectedDealer.latitude.replace(
                ",",
                "."
              )},${selectedDealer.longitude.replace(",", ".")}`}
            >
              Show route
            </a>
          </p>
          <p>
            <a
              className="btn btn-outline-primary width-100"
              href={`mailto:${selectedDealer.email}`}
            >
              Email
            </a>
          </p>
        </div>
      </div>
    );
  };
  return (
    <div className="row mt-20">
      <div className="col-md-12  border-0">
        <div className="card-header">
          <h3>{title}</h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                {dealers
                  .slice(
                    (currentPage - 1) * dealers_per_page,
                    currentPage * dealers_per_page
                  )
                  .map((item: any, index: number) => {
                    return (
                      <div
                        className={`col-12 dealer-wrapper cursor-pointer`}
                        key={index}
                      >
                        <div
                          className={`pb-20 pt-20  ${
                            selectedDealer && selectedDealer.id === item.id
                              ? "p-inline-20 background-grey"
                              : ""
                          }`}
                          onClick={() => {
                            setSelectedDealer(item);
                            setMaintenanceTypes(item.maintenance_types);
                          }}
                        >
                          <p className="m-0">
                            <span className="m-0 color-primary text-decoration-underline text-italic">
                              {item.distance.toFixed(2)} km away
                            </span>
                          </p>
                          <h4 className="m-0">{item.name}</h4>
                          <p className="m-0">
                            <i>{item.brand}</i>
                          </p>
                        </div>
                      </div>
                    );
                  })}
                {dealers.length > 5 ? (
                  <div className="pagination text-center">
                    <button
                      className="btn btn-primary mr-20"
                      onClick={() => {
                        setCurrentPage(currentPage - 1);
                      }}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        setCurrentPage(currentPage + 1);
                      }}
                      disabled={
                        currentPage ===
                        Math.ceil(dealers.length / dealers_per_page)
                      }
                    >
                      Next
                    </button>
                  </div>
                ) : null}
              </div>
            </div>

            {selectedDealer ? (
              <div className="col-md-6 d-flex border-primary background-grey mt-mobile-20">
                {renderSelectedDealerList()}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealersList;
