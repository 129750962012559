import { useEffect } from 'react';
import { getCurrentUser } from '../../services/auth.service';
import {
    Navigate,
    Outlet,
  } from 'react-router-dom';

interface IProps {
    // user : {
    //     id:number
    //     name:string
    // },
    redirectPath?: string,
    children:JSX.Element
}

const ProtectedRoute= ({
    redirectPath = '/login',
    children,
  }:IProps)=> {
    const currentUser = getCurrentUser();


    if (!currentUser) {
      return <Navigate to={redirectPath} replace />;
    }
    return children ? children : <Outlet />;
  };

export default ProtectedRoute;