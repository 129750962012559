import React, { Fragment, useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import { useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import { getVehicle } from "../../services/user.service";
import { TyreInterface } from "../../interfaces/TyreInterface";

const SingleVehicle: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [vehicle, setVehicle] = useState<any>();

    const {id} = useParams();
    useEffect(() => {
     
      if(id) {
        getVehicle(id)
        .then((res) => {
          setVehicle(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
      }else{
        navigate("/vehicles");
      }
    }, [navigate, id]);

  const renderTyresRow = (tyres: TyreInterface[]) => {
    return tyres.map((tyre: TyreInterface, index: number) => {
      return (
        <div className="col-3 col-md-3 mb-8 p-3" key={index}>
        <div
          className={`card mouse-pointer tyre-card`}>
            {/* <div className="card-header">
              <h5 className="card-title">
                Tyre {tyre.position} - {tyre.tyre_brand}
              </h5>
            </div>
            <div className="card-body">
              <p>
                <b>EAN</b>: <i>{tyre.ean}</i>
              </p>
              {tyre.description !== null && tyre.description !== undefined && tyre.description !== "" ? <p>
                  <b>Description</b>: <i>{tyre.description}</i>
                </p>:
              <Fragment> 
                <p>
                  <b>Rim Diameter</b>: <i>{tyre.rim_diameter}</i>
                </p>
                <p>
                  <b>Serie</b>: <i>{tyre.serie}</i>
                </p>
                <p>
                  <b>Width</b>: <i>{tyre.width}</i>
                </p>
              </Fragment>
            }
            </div> */}
          </div>
        </div>
      );
    });
  };

  const renderTyres = () => {
    if(vehicle.tyres.length > 0) {
      let tyres = {} as any;
      vehicle.tyres.forEach((tyre: TyreInterface) => {
        if (tyres[tyre.axle_position] !== undefined) {
          tyres[tyre.axle_position].push(tyre);
        } else {
          tyres[tyre.axle_position] = [tyre];
        }
      });
      tyres = sortTyres(tyres);
      return Object.keys(tyres).map((key: string) => {
        return (
          <div className="row justify-content-between tyres-row" key={key}>
            {renderTyresRow(tyres[key])}
          </div>
        );
      });
    }else{
      return <p>No tyres were found in our system</p>
    }
  };
  const sortTyres = (tyres:any) => {
    Object.keys(tyres).forEach((key) => {
      let leftTyres = [] as TyreInterface[];
      let rightTyres = [] as TyreInterface[];
      tyres[key].map((tyre: TyreInterface) => {
        if(tyre.position.indexOf("L") !== -1) {
          leftTyres.push(tyre);
        }
        if(tyre.position.indexOf("R") !== -1) {
          rightTyres.push(tyre);
        }
        return tyre;
      });
      leftTyres.sort(function(a:TyreInterface, b:TyreInterface) {
        return a.position.localeCompare(b.position);
      });
      rightTyres.sort(function(a:TyreInterface, b:TyreInterface) {
        return b.position.localeCompare(a.position);
      });
      tyres[key] = [...leftTyres, ...rightTyres];
    });
    return tyres;
  }
  const renderTyresWrapper = () => {
    return (
      <Fragment>
        <h3>Tyres configuration</h3>
        {renderTyres()}
      </Fragment>
    );
  };
  
  const renderVehicleField = (cname:string, label:string, value:string) => {
    if(value !== null && value !== undefined && value !== "") {
      return (
        <div className="col-md-6 mb-8 p-3">
          <div className={cname}>
            <h4 className="m-0 mb-8">{label}</h4>
            <p>{value}</p>
          </div>
        </div>
      );
    }
  }

  const renderVehicleData = () => {
    return (
      <>
      <div className="row">
        <div className="col-md-6 col-12">
          <h1>Vehicle - {vehicle.license_plate}</h1>
        </div>
        <div className="col-md-6 mb-8 p-3 text-right">
          <Link
            to={`/vehicles/${vehicle.id}/edit`}
            className="btn btn-outline-primary"
          >
            Edit Vehicle
          </Link>
        </div>
    </div>
   
            <div className="row">
              <div className="col-md-8 col-12 offset-md-2">
                    <>
                    <div className="row">
                        <div className="col-md-12 mb-8 p-3">
                          <h2>Vehicle Data</h2>
                        </div>
                    </div>
                    <div className="row">
                       
                            {renderVehicleField("fleet_reference", "Fleet Reference", vehicle.fleet_reference)}
                            {renderVehicleField("layout_code", "Layout Code", vehicle.layout_code)}
                            {renderVehicleField("license_plate", "License plate", vehicle.license_plate)}
                            {/* {renderVehicleField("location_id", "Location ID", vehicle.location_id)}
                            {renderVehicleField("location_name", "Location name", vehicle.location_name)} */}
                            {renderVehicleField("odometer_reading", "Odometer Reading", vehicle.odometer_reading)}
                            {renderVehicleField("vin", "VIN", vehicle.vin)}
                            {renderVehicleField("fleetbridge_vehicle_id", "FleetBridge Vehicle ID", vehicle.fleetbridge_vehicle_id)} 
                            {renderVehicleField("country", "Country", vehicle.country.label)}
                      
                            {renderVehicleField("object_uid_webfleet", "Object UID Webfleet", vehicle.object_uid_webfleet)}
                            {/* {renderVehicleField("latitude", "Latitude", vehicle.latitude)} */}
                            {/* {renderVehicleField("position_text", "Position Text", vehicle.position_text)} */}
                            {renderVehicleField("driver_name", "Driver Name", vehicle.driver_name)}


                            {renderVehicleField("type", "Type",  vehicle.type.label)}
                            {/* {renderVehicleField("longitude", "Longitude", vehicle.longitude)} */}
                            {renderVehicleField("label", "Label", vehicle.label)}
                            {renderVehicleField("driver_phone", "Driver phone", vehicle.driver_phone)}
                    </div>
                    <div className="row">
                        <div className="col-md-12 mb-8 p-3">
                          {renderVehicleField("notes", "Notes", vehicle.notes)}
                        </div>
                    </div>
                    </>
                    <div className="row">
                        {renderTyresWrapper()}
                    </div>    
                </div>
            </div>
            </>
    )
  }
  return (
    <Layout>
        <>
          {vehicle !== undefined && vehicle !== null ? renderVehicleData() : <div>Loading...</div>}
        </>
    </Layout>
  );
};
export default SingleVehicle;
