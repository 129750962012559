import * as React from "react";

type IProps = {
  title: string;
  license_plate: string;
  latitude?: string;
  longitude?: string;
  type: string;
};

const VehicleDetails: React.FC<IProps> = ({
  title,
  license_plate,
  latitude,
  longitude,
  type,
}) => {
  return (
    <>
      <h3>{title}</h3>
      <p>
        Vehicle type: <b>{type}</b>
      </p>
      <p>
        License plate: <b>{license_plate}</b>
      </p>
      {latitude && longitude ? (
      <p>
        GPS location:{" "}
        <b>
          {latitude}, {longitude}
        </b>
      </p>
      ) : null}
    </>
  );
};

export default VehicleDetails;
