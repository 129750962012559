import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { register } from "../../services/auth.service";
import Layout from "../../components/Layout/Layout";
import { Navigate, useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../utils/helpers";
import Select from 'react-select'
import { createAccount } from "../../services/user.service";

const CreateAccount: React.FC = () => {
  
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const navigate = useNavigate();
  const [language, setLanguage] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  let query = useQuery();

  useEffect(() => {
  // if(!params.invite_token){
  //     return navigate("/");
  //   }
  } , [])

  const initialValues: {
    name: string;
    description: string;
    account_number: string;
    contract_name: string;
    contract_account_number: string;
    webfleet_username: string;
    webfleet_password: string;
    webfleet_api_key: string;
  } = {
    name: "",
    description: "",
    account_number: "",
    contract_name: "",
    contract_account_number: "",
    webfleet_username: "",
    webfleet_password: "",
    webfleet_api_key: ""
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required!"),
    description: Yup.string().nullable(),
    account_number: Yup.string().required("This field is required!"),
    contract_name: Yup.string().required("This field is required!"),
    contract_account_number: Yup.string().required("This field is required!"),
    webfleet_username: Yup.string().required("This field is required!"),
    webfleet_password: Yup.string().required("This field is required!"),
    webfleet_api_key: Yup.string().required("This field is required!"),
  });

  const handleCreateAccount = (formValue: {
    name: string;
    description: string|null;
    account_number: string;
    contract_name: string;
    contract_account_number: string;
    webfleet_username: string;
    webfleet_password: string;
    webfleet_api_key: string;
  }) => {
    const { name, description, account_number, contract_name, contract_account_number, webfleet_username, webfleet_password, webfleet_api_key } = formValue;
    setMessage("");
    setLoading(true);
    createAccount(name, description, account_number, contract_name, contract_account_number, webfleet_username, webfleet_password, webfleet_api_key).then(
      () => {
        return navigate("/accounts");
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(error)
        setLoading(false);
        setMessage(resMessage);
      }
    );
  };
  return (
    <Layout>
      <div className="col-md-6 col-12 offset-md-3">
        <div>
          <h1>Create an account</h1>
          <p>Use the form below to create a business account</p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleCreateAccount}
          >
            <Form>
              <div className="form-group">
                <label htmlFor="name">WebFleet Account Name</label>
                <Field name="name" type="text" className="form-control" />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="description">Description</label>
                <Field
                  name="description"
                  type="textarea"
                  className="form-control"
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="account_number">WebFleet Account Number</label>
                <Field
                  name="account_number"
                  type="text"
                  className="form-control"
                />
                <ErrorMessage
                  name="account_number"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="contract_name">Bridgestone Account name</label>
                <Field name="contract_name" type="text" className="form-control" />
                <ErrorMessage
                  name="contract_name"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="contract_account_number">Bridgestone account number</label>
                <Field name="contract_account_number" type="text" className="form-control" />
                <ErrorMessage
                  name="contract_account_number"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="webfleet_username">WebFleet username</label>
                <Field name="webfleet_username" type="text" className="form-control" />
                <ErrorMessage
                  name="webfleet_username"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="webfleet_password">WebFleet password</label>
                <Field name="webfleet_password" type="password" className="form-control" />
                <ErrorMessage
                  name="webfleet_password"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="webfleet_api_key">WebFleet API key</label>
                <Field name="webfleet_api_key" type="password" className="form-control" />
                <ErrorMessage
                  name="webfleet_api_key"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-block btn-outline-primary"
                  disabled={loading}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Create an Account</span>
                </button>
              </div>

              {message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
            </Form>
          </Formik>
        </div>
      </div>
    </Layout>
  );
};
export default CreateAccount;
