import { useEffect, useState } from "react";
import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import { deleteVehicle, getAllVehicles } from "../../services/user.service";
import Moment from "react-moment";
import { VehicleInterface } from "./VehicleInterfeace";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { confirm } from "react-confirm-box";

const Vehicles = () => {
  const [vehicles, setVehicles] = useState({ data: [{} as VehicleInterface], pagination: {totalPages:1} });
  const [currentPage, setCurrentPage] = useState(1); 

  useEffect(() => {
    getAllVehicles()
      .then((res) => {
        setVehicles(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const deleteVehicleHandler = async (id: number) => {
    const result = await confirm(
      `Are you sure you want to delete vehicle with id=${id}?`,
      {
        classNames: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-success",
        },
      }
    );
    if (result) {
      deleteVehicle(id).then((res) => {
          getAllVehicles()
            .then((res) => {
                setVehicles(res);
            })
            .catch((err) => {
              console.log(err);
            });
      });
    }
  };

  const renderAllVehicles = () => {
    return vehicles.data.map((vehicle: VehicleInterface) => {
      return (
        <tr key={vehicle.id}>
          <td>{vehicle.id}</td>
          <td>{vehicle.license_plate}</td>
          <td>{vehicle.fleet_reference}</td>
          <td>{vehicle.vin}</td>

          <td>{vehicle.object_uid_webfleet}</td>
          <td>
            <Moment format="YYYY/MM/DD">{vehicle.updated_at}</Moment>
          </td>
          <td className="action-2">
            <span className="btn btn-success mblock-4 mr-8">
              <Link
                className="d-flex color-white justify-content-between align-items-center"
                to={`/vehicles/${vehicle.id}`}
              >
                <FontAwesomeIcon
                  icon={faCirclePlus}
                  className="d-inline mr-8"
                />{" "}
                Details
              </Link>{" "}
            </span>
            <span className="btn btn-danger mblock-4" onClick={() => deleteVehicleHandler(vehicle.id)}>
                <Link
                  className="d-flex color-white justify-content-between align-items-center"
                  to=""
                >
                  <FontAwesomeIcon icon={faTrash} className="d-inline mr-8" />
                  Delete
                </Link>
              </span>
          </td>
        </tr>
      );
    });
  };
  return (
    <Layout>
      <React.Fragment>
        <>
          <h2>Vehicles</h2>
          <div style={{overflowX:"auto"}}>

          <table className="table table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>License Plate</th>
                <th>Fleet Reference</th>
                <th>VIN</th>
                <th>Object UID Webfleet</th>
                <th>Last Updated At</th>
                <th>More</th>
              </tr>
            </thead>
            <tbody>{renderAllVehicles()}</tbody>
          </table>
          {vehicles.pagination !== undefined && vehicles.pagination !== null && vehicles.pagination.totalPages > 1 ? (
                        <div className="pagination text-center">
                        <button
                        className="btn btn-primary mr-20"
                        onClick={() => {
                            
                            getAllVehicles(currentPage-1).then((res) => {
                                setVehicles(res);
                            });
                            
                            setCurrentPage(currentPage - 1);
                        }}
                        disabled={currentPage === 1}
                        >
                        Previous
                        </button>
                        <button
                        className="btn btn-primary"
                        onClick={() => {
                          getAllVehicles(currentPage+1).then((res) => {
                            setVehicles(res);
                            });
                            setCurrentPage(currentPage + 1);
                        }}
                        disabled={
                            currentPage === vehicles.pagination.totalPages
                        }
                        >
                        Next
                        </button>
                    </div>) : null}
          </div>
        </>
      </React.Fragment>
    </Layout>
  );
};

export default Vehicles;
