import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { getCurrentUser } from '../../services/auth.service';

function Welcome() {
  const currentUser = getCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    if(currentUser){  
      if(currentUser.super_admin){
        navigate("/accounts");
      }else{
        navigate("/dashboard");
      }
    }else{
      navigate("/login");
    }
  }, [currentUser, navigate]);
 

  return(
    <>
      
    </>
  )

}

export default Welcome;