import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

type IProps = {
    handleSelectedServices: (service: any) => void;
    service: {
        label: string;
        description: string;
        icon: IconName;
        slug: string;
        type: string;
    };
    selectedServices: string[];
};

const MaintenanceServiceCard: React.FC<IProps> = ({
  handleSelectedServices,
  service,
  selectedServices,
}) => {
  return (
      <div className="col-md-4 d-flex">
          <div onClick={()=>handleSelectedServices(service.slug)} className={`card cursor-pointer flex width-100 maintenance-service-card mb-20 ${selectedServices.includes(service.slug) ? "bg-primary text-white":""}`}>
              <div className="card-body">
                <div className="d-flex align-services-center text-hover-success">
                    <div className="mr-20">
                      {service.icon ?  <FontAwesomeIcon icon={['fas', service.icon]} size="2x" /> : null}
                      
                    </div>
                    <div className="px-4 flex">
                      <h4 className="m-0">{service.label}</h4>
                    </div>
                </div>
              </div>
          </div>
        </div>
  );
};

export default MaintenanceServiceCard;
