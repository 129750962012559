import * as React from "react";

type IProps = {
  title: string;
  options: { value: string; name: string }[]; 
  onClickCallback: (option: any) => void;
  selected_option: string;
};

const ServiceExtraInformation: React.FC<IProps> = ({
    title,
    options,
    onClickCallback,
    selected_option
}) => {

    const renderOptions = () => {
        return options.map((option: any, index: number) => {
            return (
                <div className={`col-12 col-md-3 mb-8`} key={index} onClick={()=>onClickCallback(option)}>
                <div
                  className={`card p-8 cursor-pointer ${option.value === selected_option ? "bg-primary color-white" : ""}`}
                  >
                    <p>
                        {option.name}
                    </p>
                  </div>
                </div>
              );
        });
    };
  return (
    <>
      <h3>{title}</h3>
      <div className="row">
        {renderOptions()}
      </div>
    </>
  );
};

export default ServiceExtraInformation;
