import {useEffect, useState} from 'react';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout';
import { useParams } from "react-router-dom";
import { deleteAccount, getAllAccounts } from '../../services/user.service';
import Moment from 'react-moment';
import { AccountInterface } from './AccountInterfeace';
import { confirm } from "react-confirm-box";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faTrash } from '@fortawesome/free-solid-svg-icons';

const Accounts =  () => {


    const [accounts, setAccounts] = useState({data:[{} as AccountInterface], pagination:{totalPages:1}});
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1); 

    useEffect(() => {
        getAllAccounts().then(res => {
            setAccounts(res);
        }).catch(err => {
            console.log(err);
        })
    } , []);

    const deleteAccountHandler = async (id: number) => {
        const result = await confirm(`Are you sure you want to delete account with id=${id}?`, {classNames:{confirmButton: "btn btn-danger", cancelButton: "btn btn-success"}});
        if (result) {
            deleteAccount(id).then(res => {

                getAllAccounts().then(res => {
                    setAccounts(res);
                }
                ).catch(err => {
                    console.log(err);
                }   
                );
            })
        }
    }

    const renderAllAccounts = () => {
        return accounts.data.map((account:any, key:number) => {
            return (
                <tr key={key}>
                    <td>{account.id}</td>
                    <td>{account.name}</td>
                    {/* <td>{account.slug}</td> */}
                    {/* <td>{account.description}</td> */}
                    <td>{account.account_number}</td>
                    <td>{account.contract_account_number}</td>
                    <td>{account.contract_name}</td>
                    <td>{account.webfleet_username}</td>
                    <td><Moment format="YYYY/MM/DD">{account.created_at}</Moment></td>
                    {/* <td><Moment format="YYYY/MM/DD">{account.updated_at}</Moment></td> */}
                    <td className='action-2'><span className='btn btn-success mblock-4 mr-8'><Link className='d-flex color-white justify-content-between align-items-center' to={`/accounts/${account.id}`}><FontAwesomeIcon icon={faCirclePlus} className="d-inline mr-8" /> Details</Link> </span><span onClick={()=>deleteAccountHandler(account.id)} className='btn btn-danger mblock-4'><Link className='d-flex color-white justify-content-between align-items-center' to=""><FontAwesomeIcon icon={faTrash} className="d-inline mr-8" />Delete</Link></span></td>
                </tr>
                )   
            }
        )
    }
    return(
        <Layout>
            <React.Fragment>
                <>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <h2>Accounts</h2>
                        </div>
                        <div className="col-12 col-md-6 text-right align-self-center">
                            <Link to="/accounts/create" className="btn btn-outline-primary">Create Account</Link>
                        </div>
                    </div>
                    <div style={{overflowX:"auto"}}>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                {/* <th>Slug</th> */}
                                {/* <th>Description</th> */}
                                <th>Account Number</th>
                                <th>Contract Account Number</th>
                                <th>Contract Name</th>
                                <th>Webfleet Username</th> 
                                <th>Created At</th>
                                {/* <th>Updated At</th> */}
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderAllAccounts()}
                        </tbody>
                    </table>
                    {accounts.pagination !== undefined && accounts.pagination !== null && accounts.pagination.totalPages > 1 ? (
                        <div className="pagination text-center">
                        <button
                        className="btn btn-primary mr-20"
                        onClick={() => {
                            
                            getAllAccounts(currentPage-1).then((res) => {
                                setAccounts(res);
                            });
                            
                            setCurrentPage(currentPage - 1);
                        }}
                        disabled={currentPage === 1}
                        >
                        Previous
                        </button>
                        <button
                        className="btn btn-primary"
                        onClick={() => {
                            getAllAccounts(currentPage+1).then((res) => {
                                setAccounts(res);
                            });
                            setCurrentPage(currentPage + 1);
                        }}
                        disabled={
                            currentPage === accounts.pagination.totalPages
                        }
                        >
                        Next
                        </button>
                    </div>) : null}
                    </div>
                </>
            </React.Fragment>
        </Layout>
    );
}

export default Accounts;