import { useEffect, useState } from "react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import { cancelServiceRequest, cancelServiceRequestByAccessToken, getAllServices, getServiceRequestsPerAccount, getServiceRequestsPerVehicle } from "../../services/user.service";
import Moment from "react-moment";
import { ServiceInterface } from "./ServiceInterface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { confirm } from "react-confirm-box";
import { getCurrentUser } from "../../services/auth.service";
import { useQuery } from "../../utils/helpers";
const Services = () => {
  const [services, setServices] = useState({ data: [{} as ServiceInterface], pagination: {totalPages:1} });
  let query = useQuery();
  const access_token = query.get("access_token");
  const account_id = query.get("account_id");
  const vehicle_id = query.get("vehicle_id");
  const navigate = useNavigate();
  const logged_in = getCurrentUser() !== null;
  const [currentPage, setCurrentPage] = useState(1); 
  
  useEffect(() => {
    if (process.env.NODE_ENV==='production' && !logged_in && !document.referrer.includes('webfleet.com')){
      console.log(document.referrer);
      return navigate("/");
    }
    getServices();
  }, []);


  const cancelServiceHandler = async (id: number, access_token:string) => {
    const result = await confirm(
      `Are you sure you want to cancel service with id=${id}?`,
      {
        classNames: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-success",
        },
      }
    );
    if (result) {
      if(logged_in){
        cancelServiceRequest(id).then((res) => {
          getServices();
        });
      }else if(access_token !== null){
        cancelServiceRequestByAccessToken(id, access_token).then((res) => {
          getServices();
        });
      }
      
      }
  };

  const getServices = () => {
      
      if(access_token !== null && account_id !== null) {
        getServiceRequestsPerAccount(account_id, access_token).then((res) => {
          setServices(res);
        })
        .catch((err) => {
          console.log(err);
        });
      }else if (access_token !== null && vehicle_id !== null) {
        getServiceRequestsPerVehicle(vehicle_id, access_token).then((res) => {
          setServices(res);
        })
        .catch((err) => {
          console.log(err);
        });
      } else if(logged_in) {
        getAllServices()
          .then((res) => {
            setServices(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      else{
        return navigate("/");
      }
  }

  const renderAllServices = () => {
    return services.data.map((service: ServiceInterface) => {
      return (
        <tr key={service.id}>
          <td>{service.vehicle_license_plate}</td>
          <td>
            {service.type !== undefined && service.type !== null
              ? service.type.label
              : ""}
          </td>
          <td>
            <Moment format="YYYY/MM/DD HH:MM">{service.created_at}</Moment>
          </td>

          <td>{service.status !== undefined ? service.status.label : ""}</td>
          <td className="action-2">
            <span className="btn btn-success mblock-4 mr-8">
              <Link
                className="d-flex color-white justify-content-between align-items-center"
                to={`/services/${service.id}${logged_in ? "" : "?access_token=" + service.access_token}`}
              >
                <FontAwesomeIcon
                  icon={faCirclePlus}
                  className="d-inline mr-8"
                />{" "}
                Details
              </Link>{" "}
            </span>{" "}
            <span onClick={() => cancelServiceHandler(service.id, service.access_token)} className={`btn btn-danger mblock-4 ${service.status !== undefined && service.status!== null && service.status.slug !== "opened" ? 'btn-disabled':null}`}>
              <Link
                className="d-flex color-white justify-content-between align-items-center"
                to=""
              >
                <FontAwesomeIcon icon={faTimes} className="d-inline mr-8" />{" "}
                Cancel
              </Link>
            </span>
          </td>
        </tr>
      );
    });
  };

  const renderServicesContent = () => {
    return(
      <React.Fragment>
        <>
          <h2>Services</h2>
          <div style={{overflowX:"auto"}}>

          <table className="table table-striped">
            <thead>
              <tr>
                <th>Vehicle ID</th>
                <th>Service Type</th>
                <th>Requested Time</th>
                <th>Status</th>
                <th>More</th>
              </tr>
            </thead>
            <tbody>{renderAllServices()}</tbody>
          </table>
          {services.pagination !== undefined && services.pagination !== null && services.pagination.totalPages > 1 ? (
             <div className="pagination text-center">
             <button
               className="btn btn-primary mr-20"
               onClick={() => {
                if(vehicle_id !== null && access_token !== null){
                  getServiceRequestsPerVehicle(vehicle_id, access_token, null, currentPage).then((res) => {
                    setServices(res);
                  })
                }else{
                  getAllServices(currentPage-1).then((res) => {
                    setServices(res);
                  })
                }
                setCurrentPage(currentPage - 1);
               }}
               disabled={currentPage === 1}
             >
               Previous
             </button>
             <button
               className="btn btn-primary"
               onClick={() => {
                if(vehicle_id !== null && access_token !== null){
                  getServiceRequestsPerVehicle(vehicle_id, access_token, null, currentPage).then((res) => {
                    setServices(res);
                  })
                }else{
                  getAllServices(currentPage+1).then((res) => {
                    setServices(res);
                  })
                }
                setCurrentPage(currentPage + 1);
               }}
               disabled={
                 currentPage === services.pagination.totalPages
               }
             >
               Next
             </button>
           </div>) : null}
          </div>
        </>
      </React.Fragment>
    )
  }

  const renderContent = () => {
    if(logged_in){
      return <Layout>{renderServicesContent()}</Layout>;
    }else{
      return <Layout menu={false} button={true} button_text={"Return to Webfleet"} button_link={"https://live-wf.webfleet.com/web/index.html#/map"}>{renderServicesContent()}</Layout>;
    }
  }


  return renderContent();
};

export default Services;
