import {useEffect} from 'react';
import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../components/Layout';
import { useParams } from "react-router-dom";
import { getCurrentUser } from '../../services/auth.service';
import AdminDashoard from './AdminDashboard';
import UserDashboard from './UserDashboard';

const Dashboard =  () => {
  const currentUser = getCurrentUser();

  return(
    <Layout>
      <React.Fragment>
        {currentUser.super_admin ? <AdminDashoard /> : <UserDashboard />}
      </React.Fragment>
    </Layout>
  );
}

export default Dashboard;