import React, { useState } from "react";
import { GoogleMap, InfoWindowF, MarkerF } from "@react-google-maps/api";
import LocationIcon from "../../assets/images/location-32.png";
// const markers = [
//   {
//     id: 1,
//     name: "Chicago, Illinois",
//     position: { lat: 41.881832, lng: -87.623177 }
//   },
//   {
//     id: 2,
//     name: "Denver, Colorado",
//     position: { lat: 39.739235, lng: -104.99025 }
//   },
//   {
//     id: 3,
//     name: "Los Angeles, California",
//     position: { lat: 34.052235, lng: -118.243683 }
//   },
//   {
//     id: 4,
//     name: "New York, New York",
//     position: { lat: 40.712776, lng: -74.005974 }
//   }
// ];
type IProps = {
  markers: any[];
  zoom: number;
  center: { lat: number; lng: number };
  currentPosition: {
    id: number;
    name: string;
    position: { lat: number; lng: number };
  },
  handleSelectedDealerCallback : (id:number) => void
};
const Map: React.FC<IProps> = ({ markers, zoom, center, currentPosition, handleSelectedDealerCallback}) => {
  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker: any) => {
    handleSelectedDealerCallback(marker);
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map: any) => {
    // const bounds = new google.maps.LatLngBounds();
    // markers.forEach(({ position }) => bounds.extend(position));
    // map.fitBounds(bounds);
  };
  
  return (
    <GoogleMap
    //   onLoad={handleOnLoad}
      onClick={() => setActiveMarker(null)}
      zoom={zoom}
      center={center}
      mapContainerClassName="map-wrapper"
    >
      {markers.map(({ id, name, position }) => {
        return(
        <MarkerF
          key={id}
          position={position}
          onClick={() => handleActiveMarker(id)}
        >
          {activeMarker === id ? (
            <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
              <div>{name}</div>
            </InfoWindowF>
          ) : null}
        </MarkerF>
      )
          }
      )}
      <MarkerF
          key={currentPosition.id}
          position={currentPosition.position}
          onClick={() => handleActiveMarker(currentPosition.id)}
          icon={LocationIcon}
        >
          {activeMarker === currentPosition.id ? (
            <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
              <div>{currentPosition.name}</div>
            </InfoWindowF>
          ) : null}
        </MarkerF>
    </GoogleMap>
  );
};

export default Map;
