import React, { Fragment, useEffect, useRef, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Layout from "../../../components/Layout/Layout";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../../utils/helpers";
import Select from "react-select";
import { TyreInterface } from "../../../interfaces/TyreInterface";
import {
  createRequest,
  getAllLanguages,
  getServiceRequestsPerVehicle,
  getVehicleByAccessToken,
} from "../../../services/user.service";
import { ServiceInterface } from "../ServiceInterface";
import Moment from "react-moment";
import { confirm } from "react-confirm-box";
import { getCurrentUser } from "../../../services/auth.service";
import VehicleDetails from "../../../components/VehicleDetails/VehicleDetails";
import Tyres from "../../../components/Tyres/Tyres";

const RoadsideAssistance: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const navigate = useNavigate();
  const [languages, setLanguages] = useState<any[]>([]);
  const [driverLanguage, setDriverLanguage] = useState<string>("en");
  const [vehicleTrailer, setVehicleTrailer] = useState<boolean>(false);
  const [steerOrDrive, setSteerOrDrive] = useState<string>("-");
  const [tyreWidth, setTyreWidth] = useState<string>("");
  const [tyreSerie, setTyreSerie] = useState<string>("");
  const [tyreRimDiameter, setTyreRimDiameter] = useState<string>("");
  const [tyreBrand, setTyreBrand] = useState<string>("");
  const [tyrePosition, setTyrePosition] = useState<string>("");
  const [tyreEan, setTyreEan] = useState<string>("");
  const [tyreIPC, setTyreIPC] = useState<string>("");
  const [tyreLoad, setTyreLoad] = useState<string>("");
  const [tyreSpeedRating, setTyreSpeedRating] = useState<string>("");
  const [tyreType, setTyreType] = useState<string>("");
  const [locationLat, setLocationLat] = useState<string>("");
  const [locationLng, setLocationLng] = useState<string>("");
  const [locationType, setLocationType] = useState<string>("");
  const [selectedTyre, setSelectedTyre] = useState<number>(0);
  const [driverPhone, setDriverPhone] = useState<string>("");
  const [driverName, setDriverName] = useState<string>("");
  
  const [tyreDescription, setTyreDescription] = useState<string>("");
  const [note, setNote] = useState<string>("");
  const [openRequests, setOpenRequests] = useState<ServiceInterface[]>([]);
  const tyreDimensionsRef = useRef(null);
  const tyresRef = useRef(null);
  const logged_in = getCurrentUser() !== null;

  const TRAILER_TYPES = [
    "trailer",
    "truck_trailer",
    "heavyweight_truck_trailer",
  ];
  const TRUCK_TYPES = [
    "garbage_truck",
    "heavy_truck",
    "heavytruck_us",
    "heavyweight_truck",
    "medium_truck",
    "pallet_truck",
    "tanker_truck",
    "truck",
    "truck_loader_arm",
    "truck_with_trailer",
    "truck_wrecker",
  ];
  const HEAVY_VEHICLES = [
    "bus",
    "boom_lift_truck",
    "boom_lift",
    "concrete_lorry",
    "dump_truck",
    "dump_truck_open_top",
    "excavator"
  ]

  let query = useQuery();

  const access_token = query.get("access_token");
  const vehicle_id = query.get("vehicle_id");

  const [vehicle, setVehicle] = useState<any>();
  useEffect(() => {
    if (localStorage.languages) {
      setLanguages(JSON.parse(localStorage.languages));
    } else {
      getAllLanguages().then((res) => {
        setLanguages(JSON.parse(localStorage.languages));
      });
    }
    if (
      process.env.NODE_ENV === "production" &&
      !logged_in &&
      !document.referrer.includes("webfleet.com")
    ) {
      return navigate("/");
    }
    if (!access_token || !vehicle_id) {
      return navigate("/");
    } else {
      getVehicleByAccessToken(vehicle_id, access_token)
        .then((res) => {
          setVehicle(res.data);
          setLocationLat(res.data.latitude_mdeg);
          setLocationLng(res.data.longitude_mdeg);
          setLocationType(res.data.location_name);
          setDriverName(res.data.driver_name);
          setDriverPhone(res.data.driver_phone);
          setVehicleTrailer(TRAILER_TYPES.includes(res.data.type.slug));
        })
        .catch((err) => {
          console.log(err);
        });
      getServiceRequestsPerVehicle(
        vehicle_id,
        access_token,
        dateToYMD(new Date())
      ).then((res) => {
        setOpenRequests(res.data);
      });
    }
  }, [access_token, navigate, vehicle_id]);

  const dateToYMD = (date: any) => {
    let d = date.getDate();
    let m = date.getMonth() + 1; //Month from 0 to 11
    let y = date.getFullYear();
    return "" + y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d);
  };

  const initialValues: {
    trailer: boolean;
    tyre_steer_or_drive: string;
    tyre_rim_diameter: string;
    tyre_serie: string;
    tyre_width: string;
    tyre_load: string;
    tyre_ean: string;
    tyre_ipc: string;
    tyre_brand: string;
    tyre_speed_rating: string;
    tyre_type: string;
    tyre_position: string;
    location_lat: string;
    location_lng: string;
    location_type: string;
    driver_name: string;
    driver_phone: string;
    note: string;
    tyre_description: string;
  } = {
    trailer: vehicleTrailer,
    tyre_steer_or_drive: steerOrDrive,
    tyre_rim_diameter: tyreRimDiameter,
    tyre_serie: tyreSerie,
    tyre_width: tyreWidth,
    tyre_ean: tyreEan,
    tyre_ipc: tyreIPC,
    tyre_brand: tyreBrand,
    tyre_load: tyreLoad,
    tyre_speed_rating: tyreSpeedRating,
    tyre_type: tyreType,
    tyre_position: tyrePosition,
    location_lat: locationLat,
    location_lng: locationLng,
    location_type: locationType,
    driver_name: driverName,
    driver_phone: driverPhone,
    note: note,
    tyre_description: tyreDescription,
  };
  const validationSchema = Yup.object().shape({
    tyre_steer_or_drive: Yup.string().nullable(),
    tyre_rim_diameter: Yup.string().required("This field is required!"),
    tyre_serie: Yup.string().required("This field is required!"),
    tyre_width: Yup.string().required("This field is required!"),
    tyre_load: Yup.string().nullable(),
    tyre_speed_rating: Yup.string().nullable(),
    tyre_type: Yup.string().nullable(),
    driver_name: Yup.string().required("This field is required!"),
    driver_phone: Yup.string().required("This field is required!"),
    trailer: Yup.boolean(),
    note: Yup.string().nullable(),
    tyre_description: Yup.string().nullable(),
  });

  const handleRoadsideAssistance = async (formValue: {
    trailer: boolean;
    tyre_steer_or_drive: string;
    tyre_rim_diameter: string;
    tyre_serie: string;
    tyre_width: string;
    tyre_load: string;
    tyre_ean: string;
    tyre_ipc: string;
    tyre_brand: string;
    tyre_speed_rating: string;
    tyre_type: string;
    tyre_position: string;
    location_lat: string;
    location_lng: string;
    location_type: string;
    driver_name: string;
    driver_phone: string;
    note: string;
    tyre_description: string;
  }) => {
    const {
      trailer,
      tyre_steer_or_drive,
      tyre_rim_diameter,
      tyre_serie,
      tyre_width,
      tyre_ean,
      tyre_ipc,
      tyre_brand,
      tyre_load,
      tyre_speed_rating,
      tyre_type,
      tyre_position,
      location_lat,
      location_lng,
      location_type,
      driver_name,
      driver_phone,
      note,
      tyre_description,
    } = formValue;
    setMessage("");
    setLoading(true);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setLoading(false);
    if (
      openRequests.filter(
        (request) =>
          request.status.slug !== "cancelled" &&
          request.status.slug !== "completed"
      ).length > 0
    ) {
      const result = await confirm(
        `You already have opened/ongoing requests, are you sure you want to open this new request?`,
        {
          classNames: {
            confirmButton: "btn btn-danger",
            cancelButton: "btn btn-success",
          },
        }
      );
      if (!result) {
        return;
      }
    }
    createRequest(
      access_token,
      vehicle_id,
      tyre_position,
      trailer,
      tyre_steer_or_drive,
      tyre_rim_diameter,
      tyre_serie,
      tyre_width,
      tyre_ean,
      tyre_ipc,
      tyre_brand,
      tyre_load,
      tyre_speed_rating,
      tyre_type,
      location_lat,
      location_lng,
      location_type,
      driver_name,
      driver_phone,
      driverLanguage,
      note,
      tyre_description,
      "roadside-assistance",
      vehicle.account_id
    ).then(
      (res) => {
        setLoading(false);
        navigate("/thank-you");
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(error);
        setLoading(false);
        setMessage(resMessage);
      }
    );
  };
  const handleTyreClick = (tyre: any) => {
    setTyreEan(tyre.ean);
    setTyreIPC(tyre.ipc);
    setTyrePosition(tyre.position);
    setTyreSerie(tyre.serie);
    setTyreRimDiameter(tyre.rim_diameter);
    setTyreWidth(tyre.width);
    setTyreBrand(tyre.tyre_brand);
    setTyreLoad(
      `${tyre.load_index_1 ? tyre.load_index_1 : ""}${
        tyre.load_index_2 ? "-" + tyre.load_index_2 : ""
      }`
    );
    setTyreSpeedRating(
      `${tyre.speed_index_1 ? tyre.speed_index_1 : ""}${
        tyre.speed_index_2 ? "-" + tyre.speed_index_2 : ""
      }`
    );
    setTyreType(tyre.season);
    setSelectedTyre(tyre.id);
    setTyreDescription(tyre.description);
    handleScrollTo(tyreDimensionsRef);
  };
  // const renderTyresRow = (tyres: TyreInterface[]) => {
  //   return tyres.map((tyre: TyreInterface, index: number) => {
  //     return (
  //       <div className="col-3 col-md-3 mb-8 p-3">
  //         <div
  //           className={`card mouse-pointer tyre-card ${
  //             tyre.id === selectedTyre ? "bg-primary color-white" : ""
  //           }`}
  //           key={index}
  //           onClick={() => {
  //             handleTyreClick(tyre);
  //           }}
  //         >
  //           {/* <div className="card-header">
  //             <h5 className="card-title">
  //               Tyre {tyre.position} - {tyre.tyre_brand}
  //             </h5>
  //           </div>
  //           <div className="card-body">
  //             <p>
  //               <b>EAN</b>: <i>{tyre.ean}</i>
  //             </p>
  //             {tyre.description !== null && tyre.description !== undefined && tyre.description !== "" ? <p>
  //                 <b>Description</b>: <i>{tyre.description}</i>
  //               </p>:
  //             <Fragment>
  //               <p>
  //                 <b>Rim Diameter</b>: <i>{tyre.rim_diameter}</i>
  //               </p>
  //               <p>
  //                 <b>Serie</b>: <i>{tyre.serie}</i>
  //               </p>
  //               <p>
  //                 <b>Width</b>: <i>{tyre.width}</i>
  //               </p>
  //             </Fragment>
  //           }
  //           </div> */}
  //         </div>
  //       </div>
  //     );
  //   });
  // };

  // const renderTyres = () => {
  //   let tyres = {} as any;
  //   vehicle.tyres.forEach((tyre: TyreInterface) => {
  //     if (tyres[tyre.axle_position] !== undefined) {
  //       tyres[tyre.axle_position].push(tyre);
  //     } else {
  //       tyres[tyre.axle_position] = [tyre];
  //     }
  //   });
  //   tyres = sortTyres(tyres);

  //   return Object.keys(tyres).map((key: string) => {
  //     return (
  //       <div className="row justify-content-between tyres-row" key={key}>
  //         {renderTyresRow(tyres[key])}
  //       </div>
  //     );
  //   });
  // };

  // const sortTyres = (tyres: any) => {
  //   Object.keys(tyres).forEach((key) => {
  //     let leftTyres = [] as TyreInterface[];
  //     let rightTyres = [] as TyreInterface[];
  //     tyres[key].map((tyre: TyreInterface) => {
  //       if (tyre.position.indexOf("L") !== -1) {
  //         leftTyres.push(tyre);
  //       }
  //       if (tyre.position.indexOf("R") !== -1) {
  //         rightTyres.push(tyre);
  //       }
  //       return tyre;
  //     });
  //     leftTyres.sort(function (a: TyreInterface, b: TyreInterface) {
  //       return a.position.localeCompare(b.position);
  //     });
  //     rightTyres.sort(function (a: TyreInterface, b: TyreInterface) {
  //       return b.position.localeCompare(a.position);
  //     });
  //     tyres[key] = [...leftTyres, ...rightTyres];
  //   });
  //   return tyres;
  // };

  // const renderTyresWrapper = () => {
  //   return (
  //     <Fragment>
  //       <h3>Please choose the tyre affected by a defect in the below list</h3>
  //       <div ref={tyresRef}>{renderTyres()}</div>
  //     </Fragment>
  //   );
  // };

  const deleteSelectedTyre = () => {
    setTyreEan("");
    setTyreIPC("");
    setTyrePosition("");
    setTyreSerie("");
    setTyreRimDiameter("");
    setTyreWidth("");
    setTyreBrand("");
    setTyreLoad("");
    setTyreSpeedRating("");
    setTyreType("");

    setSelectedTyre(0);
    setTyreDescription("");
    handleScrollTo(tyresRef);
  };

  const handleScrollTo = (ref: any) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const renderOpenRequestsBody = () => {
    return openRequests.map((request: ServiceInterface, index: number) => {
      return (
        <tr key={request.id}>
          <td>{request.vehicle_license_plate}</td>
          <td>
            {request.type !== undefined && request.type !== null
              ? request.type.label
              : ""}
          </td>
          <td>
            <Moment format="YYYY/MM/DD HH:MM">{request.created_at}</Moment>
          </td>

          <td>{request.status !== undefined ? request.status.label : ""}</td>
        </tr>
      );
    });
  };
  const renderSteerOrDrive = () => {
    if (
      vehicleTrailer ||
      (vehicle.tyres.length > 0 &&
        ((!vehicleTrailer && !TRAILER_TYPES.includes(vehicle.type.slug)) ||
          (vehicleTrailer && TRAILER_TYPES.includes(vehicle.type.slug))))
    ) {
      return null;
    } else {
      return (
        <Fragment>
          <h3>Is the damaged tire a steer or a drive tire ?</h3>
          <div className="row">
            <div className="col-md-6 col-12">
              <span
                onClick={() => {
                  setSteerOrDrive("steer");
                }}
                className={`btn width-100 ${
                  steerOrDrive === "steer"
                    ? "btn-primary"
                    : "btn-outline-primary"
                }`}
              >
                Steer
              </span>
            </div>
            <div className="col-md-6 col-12">
              <span
                onClick={() => {
                  setSteerOrDrive("drive");
                }}
                className={`btn width-100 ${
                  steerOrDrive === "drive"
                    ? "btn-primary"
                    : "btn-outline-primary"
                }`}
              >
                Drive
              </span>
            </div>
          </div>
        </Fragment>
      );
    }
  };
  const renderOpenRequests = () => {
    if (openRequests.length > 0) {
      return (
        <Fragment>
          <h3>Open requests</h3>
          <div style={{ overflowX: "auto" }}>
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Vehicle ID</th>
                  <th>Service Type</th>
                  <th>Requested Time</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>{renderOpenRequestsBody()}</tbody>
            </table>
          </div>
        </Fragment>
      );
    }
  };
  const renderTruckOrTrailer = () => {
    return (
      <Fragment>
        <h3>Request Service Europe roadside assistance - tires only</h3>
        <div className="row">
          <div className="col-md-6 col-12">
            <span
              onClick={() =>
                vehicleTrailer
                  ? [setVehicleTrailer(false), deleteSelectedTyre()]
                  : null
              }
              className={`btn width-100 ${
                vehicleTrailer ? "btn-outline-primary" : "btn-primary"
              }`}
            >
              For my truck
            </span>
          </div>
          <div className="col-md-6 col-12">
            <span
              onClick={() =>
                !vehicleTrailer
                  ? [setVehicleTrailer(true), deleteSelectedTyre()]
                  : null
              }
              className={`btn width-100 ${
                vehicleTrailer ? "btn-primary" : "btn-outline-primary"
              }`}
            >
              For my trailer
            </span>
          </div>
        </div>
      </Fragment>
    );
  };

  const renderTyreDescription = () => {
    if (
      tyreDescription !== "" &&
      tyreDescription !== null &&
      tyreDescription !== undefined
    ) {
      return (
        <div className="row" ref={tyreDimensionsRef}>
          <div className="col-12">
            <h4 className="m-0">
              Tyre {tyrePosition} - {tyreBrand}
            </h4>
            <p>
              <i>{tyreDescription}</i>
            </p>
          </div>
        </div>
      );
    }
  };
  const renderTyreDimensionsFields = () => {
    return (
      <div className="row" ref={tyreDimensionsRef}>
        <div className="col-md-4 col-12">
          <div className="form-group">
            <label htmlFor="tyre_width">Width</label>
            <Field
              name="tyre_width"
              type="text"
              className="form-control"
              onChange={(e: any) => {
                setTyreWidth(e.target.value);
              }}
            />
            <ErrorMessage
              name="tyre_width"
              component="div"
              className="alert alert-danger"
            />
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="form-group">
            <label htmlFor="tyre_serie">Ratio</label>
            <Field
              name="tyre_serie"
              type="text"
              className="form-control"
              onChange={(e: any) => {
                setTyreSerie(e.target.value);
              }}
            />
            <ErrorMessage
              name="tyre_serie"
              component="div"
              className="alert alert-danger"
            />
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="form-group">
            <label htmlFor="tyre_rim_diameter">Rim Diameter</label>
            <Field
              name="tyre_rim_diameter"
              type="text"
              className="form-control"
              onChange={(e: any) => {
                setTyreRimDiameter(e.target.value);
              }}
            />
            <ErrorMessage
              name="tyre_rim_diameter"
              component="div"
              className="alert alert-danger"
            />
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="form-group">
            <label htmlFor="tyre_brand">Brand</label>
            <Field
              name="tyre_brand"
              type="text"
              className="form-control"
              onChange={(e: any) => {
                setTyreBrand(e.target.value);
              }}
            />
            <ErrorMessage
              name="tyre_brand"
              component="div"
              className="alert alert-danger"
            />
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="form-group">
            <label htmlFor="tyre_load">Load</label>
            <Field
              name="tyre_load"
              type="text"
              className="form-control"
              onChange={(e: any) => {
                setTyreLoad(e.target.value);
              }}
            />
            <ErrorMessage
              name="tyre_load"
              component="div"
              className="alert alert-danger"
            />
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="form-group">
            <label htmlFor="tyre_speed_rating">Speed Rating</label>
            <Field
              name="tyre_speed_rating"
              type="text"
              className="form-control"
              onChange={(e: any) => {
                setTyreSpeedRating(e.target.value);
              }}
            />
            <ErrorMessage
              name="tyre_speed_rating"
              component="div"
              className="alert alert-danger"
            />
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="form-group">
            <label htmlFor="tyre_type">Tyre type</label>
            <Select
              name="tyre_type"
              className="select-dropdown"
              options={[
                { value: "Summer", label: "Summer" },
                { value: "Winter", label: "Winter" },
                { value: "AllSeasons", label: "All Seasons" },
                { value: "Nordic", label: "Nordic" },
              ]}
              onChange={(value: any) => {
                setTyreType(value.value);
              }}
            />
            <ErrorMessage
              name="tyre_type"
              component="div"
              className="alert alert-danger"
            />
          </div>
        </div>
      </div>
    );
  };
  const renderTyreDimensions = () => {
  
    if(selectedTyre && (tyreDescription === "" ||
    tyreDescription === null ||
    tyreDescription === undefined )){
      return [
        <h3 className="pt-20">
          Please fill in the missing tire information for easier tire
          identification
        </h3>,
        renderTyreDimensionsFields(),
      ];
    }
    if (
      vehicle.tyres !== null &&
      vehicle.tyres.length > 0 &&
      vehicle.tyres.filter((el: TyreInterface) => el.description).length !== 0
    ) {
      if (!vehicleTrailer && !TRAILER_TYPES.includes(vehicle.type.slug)) {
        return [
          tyreDescription !== "" &&
          tyreDescription !== null &&
          tyreDescription !== undefined ? (
            <h2 className="color-primary">Selected Tyre</h2>
          ) : null,
          renderTyreDescription(),
        ];
      }
      if (vehicleTrailer && TRAILER_TYPES.includes(vehicle.type.slug)) {
        return [
          tyreDescription !== "" &&
          tyreDescription !== null &&
          tyreDescription !== undefined ? (
            <h2 className="color-primary">Selected Tyre</h2>
          ) : null,
          renderTyreDescription(),
        ];
      }
      if (
        (vehicleTrailer && !TRAILER_TYPES.includes(vehicle.type.slug)) ||
        (!vehicleTrailer && TRAILER_TYPES.includes(vehicle.type.slug))
      ) {
        return [
          <h3 className="pt-20">
            Please fill in the missing tire information for easier tire
            identification
          </h3>,
          renderTyreDimensionsFields(),
        ];
      }
    } else {
      return [
        <h3 className="pt-20">
          Please fill in the tire dimensions for easier case handling by your
          Roadside Assistance provider
        </h3>,
        renderTyreDimensionsFields(),
      ];
    }
  };

  const renderVehicleTyres = () => {
    if (vehicle.tyres !== null && vehicle.tyres.length > 0) {
      if (!vehicleTrailer && !TRAILER_TYPES.includes(vehicle.type.slug)) {
        return (
          <Tyres
            title="Please choose the tyre affected by a defect in the below list"
            vehicle_tyres={vehicle.tyres}
            onClickCallback={handleTyreClick}
            selected_tyre={selectedTyre}
          />
        );
      }
      if (vehicleTrailer && TRAILER_TYPES.includes(vehicle.type.slug)) {
        return (
          <Tyres
            title="Please choose the tyre affected by a defect in the below list"
            vehicle_tyres={vehicle.tyres}
            onClickCallback={handleTyreClick}
            selected_tyre={selectedTyre}
          />
        );
      }
    }
  };

  const renderVehicleDetails = () => {
    return (
      <Fragment>
        <div className="col-md-8 col-12 offset-md-2">
          <div>
            <VehicleDetails
              title="Vehicle details"
              type={vehicle.type.label}
              latitude={vehicle.latitude}
              longitude={vehicle.longitude}
              license_plate={vehicle.license_plate}
            />
            {renderOpenRequests()}
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleRoadsideAssistance}
              enableReinitialize={true}
            >
              <Form>
                {renderTruckOrTrailer()}
                {renderSteerOrDrive()}

                {renderVehicleTyres()}

                {/* {renderTyreDimensionsTitle()} */}
                {renderTyreDimensions()}

                {selectedTyre !== 0 ? (
                  <span
                    className="btn btn-outline-primary mt-12 mb-20"
                    onClick={() => deleteSelectedTyre()}
                  >
                    Delete selected tyre
                  </span>
                ) : null}

                <div className="form-group mt-40">
                  <label htmlFor="note">
                    Note to the Roadside Assistance service provider
                  </label>
                  <textarea
                    name="note"
                    className="form-control"
                    onChange={(e: any) => {
                      setNote(e.target.value);
                    }}
                  />
                  <ErrorMessage
                    name="note"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>
                <h3>Contact person details</h3>
                <div className="form-group">
                  <label htmlFor="driver_name">Driver name</label>
                  <Field
                    name="driver_name"
                    type="text"
                    className="form-control"
                    onChange={(e: any) => {
                      setDriverName(e.target.value);
                    }}
                  />
                  <ErrorMessage
                    name="driver_name"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="driver_phone">Driver phone</label>
                  <Field
                    name="driver_phone"
                    type="text"
                    className="form-control"
                    onChange={(e: any) => {
                      setDriverPhone(e.target.value);
                    }}
                  />
                  <ErrorMessage
                    name="driver_phone"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="language">Prefered language</label>
                  <Select
                    className="select-dropdown"
                    options={languages}
                    onChange={(value: any) => {
                      setDriverLanguage(value.value);
                    }}
                    value={
                      languages.filter(
                        (language: any) => language.value === driverLanguage
                      )[0]
                    }
                  />
                  <ErrorMessage
                    name="language"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>
                <p className="text-small">
                  When you choose this service , you consent that the license
                  plate and certain personal data of the driver/owner of the
                  vehicle will be shared with Bridgestone NV/SA or any of its
                  affiliates and associated companies for the purposes of
                  providing the roadside service
                </p>
                <div className="form-group action-row text-center">
                  <button
                    type="submit"
                    className="btn btn-block btn-primary color-white mt-12"
                    disabled={loading}
                  >
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Send my request to Service Europe</span>
                  </button>
                  {message && (
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {message}
                    </div>
                  </div>
                )}
                </div>
                {/* <p>
                  <b>Note</b> : by sending your request, you authorize us to
                  send data to external parties in relation with your request.
                  Details are visible in our T&C here
                </p> */}
                
              </Form>
            </Formik>
          </div>
        </div>
      </Fragment>
    );
  };
  return (
    <Layout
      menu={false}
      button={true}
      button_text={"Return to Webfleet"}
      button_link={"https://live-wf.webfleet.com/web/index.html#/map"}
    >
      {vehicle ? renderVehicleDetails() : <div>Loading...</div>}
    </Layout>
  );
};
export default RoadsideAssistance;
