import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Layout from "../../components/Layout/Layout";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { addUser } from "../../services/user.service";

const CreateUser: React.FC = () => {
  
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const navigate = useNavigate();
  const params = useParams();

  const initialValues: {
    email: string;
   
  } = {
    email: "",
    
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("This field is required!"),
  });

  const handleCreateUser = (formValue: {
    email: string;
  }) => {
    const { email } = formValue;
    setMessage("");
    setLoading(true);
    if(params.id){
      addUser(email, params.id, 2).then(
        () => {
          return navigate(`/accounts/${params.id}`);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log(error)
          setLoading(false);
          setMessage(resMessage);
        }
      );
    }
  };
  return (
    <Layout>
      <div className="col-md-6 col-12 offset-md-3">
        <div>
          <h1>Add user</h1>
          <p>Use the form below to add a user</p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleCreateUser}
          >
            <Form>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Field name="email" type="email" className="form-control" />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="alert alert-danger"
                />
              </div>      
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-block btn-outline-primary"
                  disabled={loading}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Add user</span>
                </button>
              </div>

              {message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
            </Form>
          </Formik>
        </div>
      </div>
    </Layout>
  );
};
export default CreateUser;
