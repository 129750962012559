import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getCurrentUser, register } from "../../services/auth.service";
import Layout from "../../components/Layout/Layout";
import { Navigate, useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../utils/helpers";
import Select from 'react-select'
import { createAccount, getAccount, getAccountNonAdmin, updateAccount, updateAccountNonAdmin } from "../../services/user.service";
import { AccountInterface } from "./AccountInterfeace";

const EditAccount: React.FC = () => {
  const currentUser = getCurrentUser();
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const navigate = useNavigate();

  const [account, setAccount] = useState({} as AccountInterface);

  const params = useParams();

  useEffect(() => {
      if(params.id){
        if(currentUser.super_admin){
          getAccount(params.id).then(res => {
              setAccount(res.data);
          }).catch(err => {
              console.log(err);
          })
        }else{
            getAccountNonAdmin().then(res => {
                setAccount(res.data);
            }).catch(err => {
                console.log(err);
            })
        }
      }
  } , []);

  const initialValues: {
    name: string;
    description: string|null;
    account_number: string;
    contract_name: string;
    contract_account_number: string;
    webfleet_username: string;
    webfleet_password: string;
    webfleet_api_key: string;
  } = {
    name: account.name,
    description: account.description,
    account_number: account.account_number,
    contract_name: account.contract_name,
    contract_account_number: account.contract_account_number,
    webfleet_username: account.webfleet_username,
    webfleet_password: "",
    webfleet_api_key: ""
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required!"),
    description: Yup.string().nullable(),
    account_number: Yup.string().required("This field is required!"),
    contract_name: Yup.string().required("This field is required!"),
    contract_account_number: Yup.string().required("This field is required!"),
    webfleet_username: Yup.string().required("This field is required!"),
    webfleet_password: Yup.string().nullable(),
    webfleet_api_key: Yup.string().nullable(),
  });

  const handleEditAccount = (formValue: {
    name: string;
    description: string|null;
    account_number: string;
    contract_name: string;
    contract_account_number: string;
    webfleet_username: string;
    webfleet_password: string;
    webfleet_api_key: string;
  }) => {
    const { name, description, account_number, contract_name, contract_account_number, webfleet_username, webfleet_password, webfleet_api_key } = formValue;
    setMessage("");
    setLoading(true);
    if(currentUser.super_admin){
        updateAccount(params.id!==undefined ? params.id:0, name, description, account_number, contract_name, contract_account_number, webfleet_username, webfleet_password, webfleet_api_key).then(
        () => {
            return navigate("/accounts");
        },
        (error) => {
            const resMessage =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
            console.log(error)
            setLoading(false);
            setMessage(resMessage);
        }
        );
    }else{
        updateAccountNonAdmin(name, description, account_number, contract_name, contract_account_number, webfleet_username, webfleet_password, webfleet_api_key).then(
            () => {
                return navigate("/dashboard");
            },
            (error) => {
                const resMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
                console.log(error)
                setLoading(false);
                setMessage(resMessage);
            }
        );
    }
  };
  return (
    <Layout>
      <div className="col-md-6 col-12 offset-md-3">
        <div>
          <h1>{account.name}</h1>
          <p>Use the form below to update the {account.name} account</p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleEditAccount}
            enableReinitialize={true}
          >
            {props => {
                console.log(props);
                return(
            <Form>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <Field name="name" type="text" className="form-control"  value={props.values.name} />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="description">Description</label>
                <Field
                  name="description"
                  type="textarea"
                  className="form-control"
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="account_number">Account Number</label>
                <Field
                  name="account_number"
                  type="text"
                  className="form-control"
                />
                <ErrorMessage
                  name="account_number"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="contract_name">Contract name</label>
                <Field name="contract_name" type="text" className="form-control" />
                <ErrorMessage
                  name="contract_name"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="contract_account_number">Contract account name</label>
                <Field name="contract_account_number" type="text" className="form-control" />
                <ErrorMessage
                  name="contract_account_number"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="webfleet_username">WebFleet username</label>
                <Field name="webfleet_username" type="text" className="form-control" />
                <ErrorMessage
                  name="webfleet_username"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="webfleet_password">WebFleet password</label>
                <Field name="webfleet_password" type="password" className="form-control" />
                <ErrorMessage
                  name="webfleet_password"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="webfleet_api_key">WebFleet API key</label>
                <Field name="webfleet_api_key" type="password" className="form-control" />
                <ErrorMessage
                  name="webfleet_api_key"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-block btn-outline-primary"
                  disabled={loading}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Save</span>
                </button>
              </div>

              {message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
            </Form>
            )
              }
            }
          </Formik>
        </div>
      </div>
    </Layout>
  );
};
export default EditAccount;
