import React, { useEffect } from "react";
import Login from "./Login/Login";
import Register from "./Register/Register";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Welcome from "./Welcome/Welcome";
import ProtectedRoute from "../components/ProtectedRoute/ProtectedRoute";
import Dashboard from "./Dashboard/Dashboard";
import Logout from "./Logout/Logout";
import Accounts from "./Accounts/Accounts";
import SingleAccount from "./Accounts/SingleAccount";
import Services from "./Services/Services";
import Vehicles from "./Vehicles/Vehicles";
import EditAccount from "./Accounts/EditAccount";
import { getAllCountries, getAllLanguages, getAllVehicleTypes } from "../services/user.service";
import CreateAccount from "./Accounts/CreateAccount";
import CreateUser from "./Users/CreateUser";
import CreateVehicle from "./Vehicles/CreateVehicle";
import MakeARequest from "./Services/MakeARequest";
import SingleService from "./Services/SingleService";
import SingleVehicle from "./Vehicles/SingleVehicle";
import ThankYou from "./ThankYou/ThankYou";
import EditVehicle from "./Vehicles/EditVehicle";
import ThankYouDealer from "./ThankYouDealer/ThankYouDealer";

const App = () => {

  useEffect(() => {
    if(!localStorage.getItem("languages")){
      getAllLanguages();
    }
    if(!localStorage.getItem("countries")){
      getAllCountries();
    }
    if(!localStorage.getItem("vehicle_types")){
      getAllVehicleTypes();
    }
  } , []);

  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="accounts"
            element={
              <ProtectedRoute>
                <Accounts />
              </ProtectedRoute>
            }
          />
          <Route
            path="accounts/create"
            element={
              <ProtectedRoute>
                <CreateAccount />
              </ProtectedRoute>
            }
          />
          <Route
            path="accounts/:id"
            element={
              <ProtectedRoute>
                <SingleAccount />
              </ProtectedRoute>
            }
          />
          
          <Route
            path="accounts/:id/edit"
            element={
              <ProtectedRoute>
                <EditAccount />
              </ProtectedRoute>
            }
          />

          <Route
            path="services"
            element={
              // <ProtectedRoute>
                <Services />
              // </ProtectedRoute>
            }
          />
          <Route
            path="vehicles"
            element={
              <ProtectedRoute>
                <Vehicles />
              </ProtectedRoute>
            }
          />
          <Route
            path="users/:id/add"
            element={
              <ProtectedRoute>
                <CreateUser />
              </ProtectedRoute>
            }
          />

          <Route
            path="vehicles/:id/add"
            element={
              <ProtectedRoute>
                <CreateVehicle />
              </ProtectedRoute>
            }
          />
          <Route
            path="make-a-request"
            element={
                <MakeARequest />
            }
          />
           <Route
            path="services/:id"
            element={
              // <ProtectedRoute>
                <SingleService />
              // </ProtectedRoute>
            }
          />
          <Route
            path="vehicles/:id"
            element={
              <ProtectedRoute>
                <SingleVehicle />
              </ProtectedRoute>
            }
          />
          <Route
            path="vehicles/:id/edit"
            element={
              <ProtectedRoute>
                <EditVehicle />
              </ProtectedRoute>
            }
          />
          <Route
            path="thank-you"
            element={
                <ThankYou />
            }
          />
            <Route
            path="thank-you-dealer"
            element={
                <ThankYouDealer />
            }
          />
          <Route path="/logout" element={<Logout />} />
          <Route path="*" element={<Welcome />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
