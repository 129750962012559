import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { register } from "../../services/auth.service";
import Layout from "../../components/Layout/Layout";
import { Navigate, useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../utils/helpers";
import Select from 'react-select'
import { getAllCountries, getAllLanguages } from "../../services/user.service";

const Register: React.FC = () => {
  
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const navigate = useNavigate();
  const [language, setLanguage] = useState<string>("");
  const [country, setCountry] = useState<string>("");

  const [countries, setCountries] = useState<any[]>([]);
  const [languages, setLanguages] = useState<any[]>([]);
  let query = useQuery();

  useEffect(() => {
  if (localStorage.countries) {
    setCountries(JSON.parse(localStorage.countries));
  }else{
    getAllCountries().then((res)=>{
      setCountries(JSON.parse(localStorage.countries));
      }
    )};
    if (localStorage.languages) {
      setLanguages(JSON.parse(localStorage.languages));
    }else{
      getAllLanguages().then((res)=>{
        setLanguages(JSON.parse(localStorage.languages));
        }
      )};
  } , [])

  const initialValues: {
    email: string;
    password: string;
    password_confirmation: string;
    name: string;
    phone_number: string;
    country_slug: string;
    language_slug: string;
    invite_token: string;
  } = {
    email: "",
    password: "",
    password_confirmation: "",
    name: "",
    phone_number: "",
    country_slug: country,
    language_slug: language,
    invite_token: query.get("invite_token") || "",
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("This field is required!"),
    password: Yup.string().required("This field is required!"),
    name: Yup.string().required("This field is required!"),
    phone_number: Yup.string().required("This field is required!"),
    invite_token: Yup.string().required("This field is required!"),
    password_confirmation: Yup.string().test(
      'equal',
      'Passwords do not match!',
      function(v) {
        const ref = Yup.ref('password');
        return v === this.resolve(ref);
      }).required("This field is required!"),
  });

  const handleRegister = (formValue: {
    email: string;
    password: string;
    password_confirmation: string;
    name: string;
    phone_number: string;
    country_slug: string;
    language_slug: string;
    invite_token: string;
  }) => {
    const { name, email, password, password_confirmation, phone_number, invite_token } = formValue;
    setMessage("");
    setLoading(true);
    register(name,email, password, password_confirmation, phone_number, country, language, invite_token).then(
      () => {
        return navigate("/dashboard");
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(error)
        setLoading(false);
        setMessage(resMessage);
      }
    );
  };
  return (
    <Layout>
      <div className="col-md-6 col-12 offset-md-3">
        <div>
          <h1>Welcome to ServiceBridge</h1>
          <p>Please fill out the form below to create your account</p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleRegister}
          >
            <Form>
              <div className="form-group">
                <label htmlFor="account">Account</label>
                <Field name="account" type="text" className="form-control" disabled value={query.get("account")}/>
                <ErrorMessage
                  name="account"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <Field name="name" type="text" className="form-control" />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Field name="email" type="email" className="form-control" />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <Field
                  name="password"
                  type="password"
                  className="form-control"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="password_confirmation">Password Confirmation</label>
                <Field
                  name="password_confirmation"
                  type="password"
                  className="form-control"
                />
                <ErrorMessage
                  name="password_confirmation"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone_number">Phone number</label>
                <Field name="phone_number" type="text" className="form-control" />
                <ErrorMessage
                  name="phone_number"
                  component="div"
                  className="alert alert-danger"
                />
              </div>

              <div className="form-group">
                <label htmlFor="language">Language</label>
                <Select className="select-dropdown" options={languages} onChange={(value:any)=>setLanguage(value.value)}/>
                <ErrorMessage
                  name="language"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="country">Country</label>
                <Select className="select-dropdown" options={countries} onChange={(value:any)=>{setCountry(value.value)}}/>
                <ErrorMessage
                  name="country"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-block btn-outline-primary"
                  disabled={loading}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Register</span>
                </button>
              </div>

              {message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
            </Form>
          </Formik>
        </div>
      </div>
    </Layout>
  );
};
export default Register;
