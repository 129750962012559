import * as React from "react";

type IProps = {
  vehicleTrailer: boolean;
  setVehicleTrailer: (vehicleTrailer: boolean) => void;
 
};

const TruckOrTrailer: React.FC<IProps> = ({
  vehicleTrailer,
  setVehicleTrailer,
}) => {
  return (
    <React.Fragment>
        <div className="row">
        <div className="col-md-6 col-12">
            <span
            onClick={() => vehicleTrailer ? [setVehicleTrailer(false)] : null}
            className={`btn width-100 ${
                vehicleTrailer ? "btn-outline-primary" : "btn-primary"
            }`}
            >
            For my truck
            </span>
        </div>
        <div className="col-md-6 col-12">
            <span
            onClick={() =>  !vehicleTrailer ? [setVehicleTrailer(true)] : null}
            className={`btn width-100 ${
                vehicleTrailer ? "btn-primary" : "btn-outline-primary"
            }`}
            >
            For my trailer
            </span>
        </div>
        </div>
    </React.Fragment>
  );
};

export default TruckOrTrailer;
