import React from 'react';
import Header from '../Header';

type IProps = {
    children? : JSX.Element,
    menu?: boolean,
    button?: boolean,
    button_link?: string,
    button_text?: string
}
const Layout:React.FC<IProps> = ({children,  menu, button, button_link, button_text}) =>{
    return(
        <div className='container app-wrapper'>
              
            <Header menu={menu} button={button} button_link={button_link} button_text={button_text}></Header>
            <main>{children}</main>
        </div>
    )
}

export default Layout;