import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { login } from "../../services/auth.service";
import Layout from "../../components/Layout/Layout";
import { Navigate } from "react-router";
import { useNavigate } from "react-router-dom";

const Login: React.FC = () => {
  
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const navigate = useNavigate();

  const initialValues: {
    email: string;
    password: string;
    account: string;
  } = {
    email: "",
    password: "",
    account: "",
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("This field is required!"),
    password: Yup.string().required("This field is required!"),
    account: Yup.string(),
  });

  const handleLogin = (formValue: {
    email: string;
    password: string;
    account: string;
  }) => {
    const { account, email, password } = formValue;
    setMessage("");
    setLoading(true);
    login(account,email, password).then(
      () => {
        return navigate("/dashboard");
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.error.message) ||
          error.message ||
          error.toString();
        console.log(error)
        setLoading(false);
        setMessage(resMessage);
      }
    );
  };
  return (
    <Layout>
      <div className="col-md-6 col-12 offset-md-3">
        <div>
          <h1>Welcome back</h1>
          <p>Please login to your account</p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleLogin}
          >
            <Form>
              <div className="form-group">
                <label htmlFor="account">Account</label>
                <Field name="account" type="text" className="form-control" />
                <ErrorMessage
                  name="account"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Field name="email" type="email" className="form-control" />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <Field
                  name="password"
                  type="password"
                  className="form-control"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-block btn-outline-primary"
                  disabled={loading}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Login</span>
                </button>
              </div>
              {message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
            </Form>
          </Formik>
        </div>
      </div>
    </Layout>
  );
};
export default Login;
