import * as React from "react";
import { TyreInterface } from "../../interfaces/TyreInterface";

type IProps = {
  title: string;
  vehicle_tyres: TyreInterface[];
  onClickCallback?: (tyre: TyreInterface) => void;
  selected_tyre?: number;
  selected_axle?: string[];
};

const Tyres: React.FC<IProps> = ({
    title,
    vehicle_tyres,
    onClickCallback,
    selected_tyre,
    selected_axle
}) => {
    const renderTyresRow = (tyres: TyreInterface[]) => {
        return tyres.map((tyre: TyreInterface, index: number) => {
          return (
            <div className="col-3 col-md-3 mb-8 p-3" key={index}>
            <div
              className={`card mouse-pointer tyre-card ${selected_tyre && tyre.id === selected_tyre ? "bg-primary color-white" : ""} ${selected_axle && selected_axle.includes(tyre.position) ? "bg-primary color-white" : ""}`}
                onClick={() => {
                    if(onClickCallback) {
                      onClickCallback(tyre);
                    }
                }}
              >
              </div>
            </div>
          );
        });
      };
    const renderTyres = () => {
        let tyres = {} as any;
        vehicle_tyres.forEach((tyre: TyreInterface) => {
          if (tyres[tyre.axle_position] !== undefined) {
            tyres[tyre.axle_position].push(tyre);
          } else {
            tyres[tyre.axle_position] = [tyre];
          }
        });
        tyres = sortTyres(tyres);
    
        return Object.keys(tyres).map((key: string) => {
          return (
            <div className="row justify-content-between tyres-row" key={key}>
              {renderTyresRow(tyres[key])}
            </div>
          );
        });
      };
      const sortTyres = (tyres:any) => {
        Object.keys(tyres).forEach((key) => {
          let leftTyres = [] as TyreInterface[];
          let rightTyres = [] as TyreInterface[];
          tyres[key].map((tyre: TyreInterface) => {
            if(tyre.position.indexOf("L") !== -1) {
              leftTyres.push(tyre);
            }
            if(tyre.position.indexOf("R") !== -1) {
              rightTyres.push(tyre);
            }
            return tyre;
          });
          leftTyres.sort(function(a:TyreInterface, b:TyreInterface) {
            return a.position.localeCompare(b.position);
          });
          rightTyres.sort(function(a:TyreInterface, b:TyreInterface) {
            return b.position.localeCompare(a.position);
          });
          tyres[key] = [...leftTyres, ...rightTyres];
        });
        return tyres;
      }

  return (
    <React.Fragment>
        <h3>{title}</h3>
        <div>{renderTyres()}</div>
    </React.Fragment>
  );
};

export default Tyres;
