import * as React from "react";
import { Link } from "react-router-dom";
import { getCurrentUser, logout } from "../../services/auth.service";
import styles from "./Nav.module.scss";
import UserLinks from "./Links/user_links.json";
import AdminLinks from "./Links/admin_links.json";
import PublicLinks from "./Links/public_links.json";
import { slide as Menu } from 'react-burger-menu';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import Logo from "../Logo/Logo";
const Nav: React.FC = () => {

const [user, setUser] = React.useState<any>(null);

React.useEffect(() => {
    const user = getCurrentUser();
    setUser(user);
} , []);

const getNavigation = () => {
    if (user) {
      if (user.super_admin) {
        return AdminLinks;
      } else {
        return UserLinks;
      }
    }
    return PublicLinks;
}
const renderNavigation = () => {
    return getNavigation().map((link:any) => {
      return (
        <li key={link.name}>
          <Link className={styles.navItem} to={link.path}>
            {link.name}
          </Link>
        </li>
      );
    }
    );
};

  return (
    <div className="navigation">
      <nav className="nav-desktop">
        <ul className={styles.nav}>{renderNavigation()}</ul>
      </nav>
      <Menu width={"100%"} className="nav-mobile" right={true} customCrossIcon={<FontAwesomeIcon width={"24px"} icon={faTimes} />} customBurgerIcon={<FontAwesomeIcon style={{top:"10px", position:"relative"}} width={"24px"} icon={faBars}/>} ><Logo /><ul className={styles.nav}>{renderNavigation()}</ul></Menu>
    </div>
  );
};

export default Nav;
